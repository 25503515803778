import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

import { VerifiedTooltip } from "../Atomics/CustomCheckTooltips/CustomCheckTooltips";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./SingleChannel.css"


function getCurrntDimensions() {
	return {
		width: window.innerWidth,
		height: window.innerHeight
	}
}
function useDimensionHook() {
	const [currentDimenstion, setCurrentDimenstion] = useState(getCurrntDimensions());

	useEffect(() => {
		const handleResize = () => { setCurrentDimenstion(getCurrntDimensions()) }

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return currentDimenstion;
}
export default function SingleChannelNav({ count }) {
	const { height, width } = useDimensionHook();

	const isMobile = width <= 768
	const history = useHistory();

	const GotoSort = (sort) => {
		history.push(`/Series?sort_by=${sort}`)
	}



	return (
		<>
			<div className="content-section">
				<div className="filter-section">

					<div className="d-flex align-items-center justify-content-between ">
						<div className="d-flex align-items-center">

							<h5 style={{ fontSize: "18px", marginBottom: "0px", paddingLeft: "10px" }}>{count} TV Series</h5>
						</div>

						<div className="btn-group season-dropdown float-right" role="group" aria-label="Basic example">
							{/* <NavDropdown title="Genres" id="basic-nav-dropdown" className="btn navbtn" aria-haspopup="true" aria-expanded="false" style={{ width: 'auto', height: '38px', zIndex: 1 }}> */}
								{/* {
						genre.map((item)=>{
							return(
								
								<NavDropdown.Item href="#" style={{ backgroundColor: 'black' }}>{item.name}</NavDropdown.Item>
							)
						})
					    } */}
							{/* </NavDropdown> */}
							<a onClick={() => GotoSort('newest')} className="btn navbtn active ">
								<i className="far fa-clock" aria-hidden="true"></i>
								{!isMobile && <span className="for-desktop"> Newest </span>}
							</a>
							{/* <a  className="btn navbtn ">
							<i className="far fa-eye" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Views </span>}
						</a>						 */}
							<a onClick={() => GotoSort('imdb')} className="btn navbtn ">
								<i className="fab fa-imdb" aria-hidden="true"></i>
								{!isMobile && <span className="for-desktop"> Rate </span>}
							</a>
							<a onClick={() => GotoSort('title')} className="btn navbtn ">
								<i className="fas fa-sort-alpha-down" aria-hidden="true"></i>
								{!isMobile && <span className="for-desktop"> Title </span>}
							</a>
							{/* <a  className="btn navbtn ">
							<i className="fas fa-calendar-alt" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Year </span>}
						</a> */}
						</div>




					</div>


					{/* <div className="btn-group season-dropdown" role="group"> */}
					{/* <NavDropdown title="Genres" id="basic-nav-dropdown" className="btn navbtn" aria-haspopup="true" aria-expanded="false" style={{width:'auto', height:'38px', zIndex:1}}>
						
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Genres</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Action</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Drama</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>History</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Adventure</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Comedy</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Family</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Action &amp; Adventure</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>War &amp; Politics</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Crime</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Mystery</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Romance</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Science Fiction</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Fantasy</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Documentary</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Animation</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Horror</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Thriller</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Music</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>TV Movie</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Western</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>War</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Soap</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Sci-Fi &amp; Fantasy</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Reality</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Kids</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>News</NavDropdown.Item>
							<NavDropdown.Item href="#" style={{backgroundColor:'black'}}>Talk</NavDropdown.Item>
						</NavDropdown> */}

					{/* </div> */}
				</div>
			</div>
		</>
	);
}
