import React, {useEffect, useRef} from "react";
const $ = window.jQuery;


const SingleCard = ({name, href='#', src}) => {   
    return (
        <>
            <div>
                <a href={href}>
                    <img className="img-fluid videoimg" src={src} />                        
                </a>
            </div>
        </>
    )
}
export default SingleCard;

