import { SELECTED_TVCHANNELFILTER } from "../action/config";

const initialState ={
    selectedtvFilterlId : ''
};

export default function TvFilterReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_TVCHANNELFILTER : 
        return{
            ...state,
            selectedtvFilterlId : action.selectedtvfilter
        }
        default :
        return state
    }
}