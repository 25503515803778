import { combineReducers } from "redux";
import TvchannelReducer from "./TvchannelReducer";
import GenerReducer from "./GenerReducer";
import FilterReducer from "./FilterReducer";
import TvFilterReducer from "./TvchannelFilter";
import CountryReducer from "./countryReducer";
import MetaReducer from "./metaReducer";

const rootReducer = combineReducers({
        ChannelObj : TvchannelReducer,
        GenerObj : GenerReducer,
        FilterObj : FilterReducer,
        TvFilterObj : TvFilterReducer,
        CountryObj : CountryReducer,
        MetaObj : MetaReducer
});

export default rootReducer;