import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import SeriesCard from "./SeriesCard";
import Paginate from "../Atomics/Paginate/Paginate";
import "./SingleChannel.css"

import FatFooter from "../Footer/FatFooter";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
import { useState } from "react";
import { useEffect } from "react";
import { cacheable } from "../../common/Caches";

const ChannelVideos = ({sorting}) => {



	const [page, setpage] = useState(1);
	const [item, setItem] = useState([]);
	let api ;

	if(sorting){
		api = `api/tv/series/?sort_by=${sorting}&page=${page}&size=50`
	}
	else{
		api =`api/tv/series/?sort_by=newest&page=${page}&size=50`
	}


	const SeriasList = (isReset) => {
		
		axios.get(APIUrl + api).then((res) => {
		try {
			if (isReset) {

				setItem(res.data.results);
			}

			else
				setItem([...item, ...res.data.results]);
			
		} catch (error) {
			
		}

		})
	}


	useEffect(() => {
		SeriasList();
		cacheable(localStorage.setItem('testList',JSON.stringify(item)))
	}, [page])

	useEffect(() => {
		SeriasList(true)
	}, [sorting])



	return (
		<>
			<div className="video-block section-padding">
				<Row className="content-section">
					{/* <Col md={12}>
						<SectionHeader   heading="Read 6753 Movie magazines for free!" />
					</Col> */}

					{item.map((list) => {
						return (
							list.poster_image &&
							<SeriesCard

								coverImageSrc={list.poster_image}
								Id={list.seriesID}
								Seriesname={list.name}

							/>
						)
					})
					}


				</Row>
			</div>
			<div style={{ textAlign: "center", paddingBottom: "10px" }}>
				{
					item.length ? <button className="loadmore" onClick={() => setpage((page) => page + 1)}>Load More...</button> : <h2></h2>
				}

			</div>

			<FatFooter />
		</>
	);
};

export default ChannelVideos;
