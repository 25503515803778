import React, { useEffect, useState } from "react";
import { Container, Navbar, Nav, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import APIUrl from "../../common/Urlcontant";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import './Birthday.css';
import axios from "axios";
import {setSelectedTvChannel} from '../../action/TvChannelAction'
import SingleChannelNav from "../Actors/SingleChannelNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import DefaultImg from '../../img/site.jpg'
import { setSelectedMeta } from "../../action/MetaAction";
import { cacheable } from "../../common/Caches";
import HeadTags from "../../common/Helmet";

const Birthday = () => {

    const [birthdate, setbirthdate] = useState([]);
    const [value, setvalue] = useState('today');
    const [changepage, setchangepage] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0);
    const [Counter, setcounter] = useState()
    const [containe , setcontaine ] = ("")
    const history = useHistory();
    const dispatch = useDispatch();

    // let containe = `Today We have ${Counter} Birthday `; 

    let Api = '';

    if (value == "today") {
        Api = APIUrl + `api/tv/artist/?birthday=${value}&page=${changepage}&size=40`;
    }
    else if (value == "current_week") {
        Api = APIUrl + `api/tv/artist/?birthday=${value}&page=${changepage}&size=40`;

    }
    else if (value == "current_month") {
        Api = APIUrl + `api/tv/artist/?birthday=${value}&page=${changepage}&size=40`;
    }
    else {

        Api = APIUrl + `api/tv/artist/?birthday=${value}&page=${changepage}&size=40`;

    }

    const BirthdayValue = async (isReset) => {


        await axios.get(Api).then((response) => {
            Metadispatch('Actor Birthday', 'Watch the world FREE', DefaultImg)
            try {
                setTotalRecords(response.data.count)
                if (isReset) {

                    setbirthdate(response.data.results);
                }
                else {

                    setbirthdate([...birthdate, ...response.data.results]);

                }
            } catch (error) {

            }

        })

    }
    const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(`Tvzinos | ${title}`, desc, image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}

    const GotoActorDetaile = (Aid, Aname) => {
        localStorage.setItem('selectedChannel', Aid);
        dispatch(
            setSelectedTvChannel(Aid)
        ).then((res) => {
            const URL = Aid + "/" + Aname.replace(/\s+/g, '-')
            history.push('/Actor/' + URL );

        }).catch((err) => {
            console.log("redux ---", err);
        })
    }


    const counter = async () => {
        await axios.get(APIUrl + `api/tv/count-statistic/`).then((res) => {

            if (value == 'today') {

                setcounter(res.data.birthday_today)


            }
            else if (value == "current_week") {

                setcounter(res.data.birthday_current_week)
              

            }
            else if (value == "current_month") {

                setcounter(res.data.birthday_current_month)
              

            }
            else {
                setcounter(res.data.birthday_today)
                setcontaine(`Today We have ${Counter} Birthdays. `)

            }

        })
    }

    useEffect(() => {
        BirthdayValue();
        cacheable(localStorage.setItem('testList',JSON.stringify(birthdate)))
    }, [changepage ])

    useEffect(() => {
        BirthdayValue(true);
        counter();

    }, [value])



    return (
        <>
            <ContentWrapper className="single-channel-page">

                <Container fluid >
                <HeadTags title='Tv Zinos | Actor Birthday' image={DefaultImg} description='Watch the world FREE' />
                    <div class="event-schedule-area-two bg-color pad100">
                        <div>
                            <div class="birthfilter" >
                                <div>
                                    <h5>{ value ? (value == 'today' ? `Today We have ${Counter} Birthdays.` : "" || value == 'current_week' ? `This week We have ${Counter} Birthdays.` : "" || value == 'current_month' ? `We have ${Counter} Birthdays this month.` : "") : ""}</h5>

                                </div>
                                <div style={{ marginRight: "15px" }}>

                                    <a style={{ borderColor: "#ffff" }} onClick={() => setvalue('today')} className="btn mb-1 active ">
                                        <i class="fa fa-solid fa-calendar"></i>
                                        <span className="for-desktop pl-2"> Birthdays Today</span>
                                    </a>

                                    <a style={{ borderColor: "#ffff" }} onClick={() => setvalue('current_week')} className="btn mb-1 active">
                                        <i className="fas fa-solid fa-calendar" aria-hidden="true"></i>
                                        <span className="for-desktop pl-2"> Birthdays This Week </span>
                                    </a>

                                    <a style={{ borderColor: "#ffff" }} onClick={() => setvalue('current_month')} className="btn mb-1 ">
                                        <i className="fas fa-sort-alpha-down" aria-hidden="true"></i>
                                        <span className="for-desktop pl-2"> Birthdays This Month </span>
                                    </a>
                                </div>
                            </div>
                            <div class="row">

                                <div class="col-lg-12 px-0">

                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane fade active show" id="home" role="tabpanel">
                                            <div class="table-responsive">

                                                <div className="row">
                                                    {
                                                        birthdate.map((item) => {
                                                            return (
                                                                <div className="col-12 col-md-6 col-xl-4 mb-4">
                                                                    <div className="boxinn ">
                                                                        <div className="objfirsdtcontinn">
                                                                            <div className="rounded imagemainn" >
                                                                                <img src={item.profile_image}></img>
                                                                            </div>
                                                                            <div className="textdecinn">
                                                                                <h5 className="mb-2">{item.name}</h5>
                                                                                <p className="mb-2">Age: {item.age}</p>
                                                                                <p className="mb-2">Birthday: {item.birthday}</p>
                                                                                <p className="mb-2">IMDB: {parseFloat(item.popularity).toFixed(2)}</p>
                                                                                <p className="mb-2">{item.place_of_birth}</p>
                                                                                <div className="text-right">
                                                                                    <a href={'/Actor/' +   item.peopleID + "/" + item.name.replace(/\s+/g, '-')} class="btn btn-dark ml-auto" onClick={()=>GotoActorDetaile(item.peopleID , item.name)} >
                                                                                        <p className="text-white">Filmography</p>
                                                                                    </a>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            )
                                                        })

                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>


                    <div style={{ textAlign: "center", paddingBottom: "10px" }}>
                        {
                            birthdate.length < totalRecords ? <button className="loadmore" onClick={() => setchangepage((changepage) => changepage + 1)}>Load More...</button> : <h2>No More Birthday</h2>
                        }
                        {/* <button>Load More</button> */}

                    </div>

                </Container>




            </ContentWrapper>

        </>
    )



}

export default Birthday;