import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import ChannelsCard from "./ChannelsCard";
import Paginate from "../Atomics/Paginate/Paginate";
import FatFooter from "../Footer/FatFooter";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
// import imgUrl from "../../img/tv.jpg";
import { useEffect, useState } from "react";
import { useQuery } from "../Search/search";
import { useSelector } from "react-redux";
import { cacheable } from "../../common/Caches";
let imgUrl = ''
// import "./SingleChannel.css"

const ChannelVideos =  () => {
	const query = useQuery();
	const [list , setList] = useState([]);
	const FilterId = useSelector((state) => state.TvFilterObj.selectedtvFilterlId );

	const [page, setPage] = useState(1);
	const [counter, setcounter] = useState(1);

	let api = '';
	const categoryId = query.get('id');
	const categoryname = query.get('name');


	if(query.get('sort_by')){

		api = APIUrl+`api/tv/channel/?page=${page}&size=60&sort_by=${query.get('sort_by')}`;

	}
	// else if(categoryId){
	// 	api = APIUrl+`api/tv/channel/?category=${categoryId}`;
	// }
	else{

		 api = APIUrl+`api/tv/channel/?page=${page}&size=60&sort_by=view`;
		
	

	}


	const AllTvChannel = async (isreset) => {

		if(FilterId){

			await axios.get(APIUrl+`api/tv/channel/?category=${FilterId}&count=40&page=${page}`).then((res)=>{
				try {
	
					// setList([...list , ...res.data.results])
					setcounter(res.data.count)
					if (isreset) {
	
						setList(res.data.results);

					}
	
					else{
					setList([...list, ...res.data.results]);
					}
					
					
					
				} catch (error) {
					
				}
			})

		}
		else{

			await axios.get(api).then((res)=>{
				try {
	
					// setList([...list , ...res.data.results])
					if (isreset) {
	
						setList(res.data.results);
					}
	
					else{
					setList([...list, ...res.data.results]);
					}
					
					
					
				} catch (error) {
					
				}
			})

		}

		
	}

	useEffect(()=>{
		AllTvChannel();
		cacheable(localStorage.setItem('testList',JSON.stringify(list)))
	},[page])

	useEffect(() => {
		AllTvChannel(true)
	}, [query.get('sort_by') , FilterId])


	return (
		<>

			<div className="video-block section-padding">
				<Row className="content-section">
					{/* <Col md={12}>
						<SectionHeader   heading="Read 6753 Movie magazines for free!" />
					</Col> */}
				{
					list.map((value)=>{
						return(
							
							 
							<ChannelsCard
								name={value.name}
								coverImageSrc={value.image?.url }
								id={value.id}
								count={counter}
							/>
						)
					})
					
				}


				</Row>
			</div>
			
			<div style={{ textAlign: "center", paddingBottom: "10px" }}>
						
							<button className="loadmore" onClick={() => setPage((page) => page + 1)}>Load More...</button> <h2></h2>
						

					</div>

			<FatFooter />
		</>
	);
};

export default ChannelVideos;
