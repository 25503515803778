import "./SingleChannel.css";
import Container from "react-bootstrap/Container";

import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useEffect, useState } from "react";
import { useQuery } from "../Search/search";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
import { setSelectedMeta } from "../../action/MetaAction";
import { useDispatch } from "react-redux";
import DefaultImg from '../../img/site.jpg'
import HeadTags from "../../common/Helmet";

const Series = () => {
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])
	const query = useQuery();
	const dispatch = useDispatch();
	const [Counter, setcounter] = useState()
	const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(`Tvzinos | ${title}`, desc, image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}  

	const counter = async () => {
		await axios.get(APIUrl + `api/tv/count-statistic/`).then((res) => {
			setcounter(res.data.tmdb_total_series)
		})
	}
	useEffect(() => {
		counter();
		Metadispatch('Series', 'Watch the world FREE ', DefaultImg)
	}, [])
	return (
		<>
			<ContentWrapper className="single-channel-page">
				<Container fluid >
				<HeadTags title='Tv Zinos | Series' image={DefaultImg} description='Watch the world FREE' />
					<SingleChannelNav count={Counter} />
					<ChannelVideos sorting={query.get('sort_by')} />
				</Container>
			</ContentWrapper>


		</>
	);
};

export default Series;
