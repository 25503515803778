import React, { useEffect, useState } from "react";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { Container } from "react-bootstrap";
import { useQuery } from "../Search/search";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
import { useSelector } from "react-redux";
import { cacheable } from "../../common/Caches";
const EpisodeDetaile =()=>{

    const query = useQuery();
    const[Episode , setEpisode] = useState({})
	const SeriesId = useSelector((state) => state.ChannelObj.selectedChannelId || localStorage.getItem('selectedChannel'));

    const EpisodeObj =()=>{
        axios.get(APIUrl + `api/tv/series/episodes/${SeriesId}/`).then((res)=>{
            setEpisode(res.data.episode)
        })
    }


    useEffect(()=>{
        EpisodeObj();
		cacheable(localStorage.setItem('testList',JSON.stringify(Episode)))
    },[])



    return(
        <ContentWrapper  className="single-channel-page">
            {/* <Container fluid> */}
            <div className="col-md-12">
				<div className="movie-header" style={{ backgroundImage: `url(${Episode.still_image})` }}>
					<div className="movie-bigtitle">
						<div className="movie-maintitle"><h2>{Episode.name}</h2></div>
						<div className="movie-smalltitle">
							<p className="movie-lefttitle">episode {Episode.episode_number} </p>
							<div className="movie-righttitle">
								<span>{parseFloat(Episode.vote_average).toFixed(2)}/10</span> <span className="imdb">  IMDB  </span>
								{/* <img style={{ width: '50px', height: '50px' }} src={flag} ></img> */}
							</div>
						</div>
					</div>
					{/* <div className="header-buttons"><button className="open-login"> <i className="far fa-heart" aria-hidden="true"></i></button></div> */}
				</div>
			</div>

            <div className="col-md-12 ">
				<div className="movie-section-title">Overview</div>
			</div>
			<div className="col-md-12">
				<p className="movie-setion-content">
					{Episode.overview}
				</p>
			</div>
            {/* </Container> */}
        </ContentWrapper>
    )
}

export default EpisodeDetaile;