import React, { useEffect, useRef } from "react";
import { setSelectedTvChannel } from '../../action/TvChannelAction.js'
import Movie from "./Movie";
const $ = window.jQuery;
import './SingleChannel.css'
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const MovieCard = ({ name, href = '#', coverImageSrc, generId, id }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    

    const GotoMovieDetaile = (Cid, Cname) => {
        localStorage.setItem('selectedChannel', id);
        dispatch(
            setSelectedTvChannel(Cid)
        ).then((res) => {
            const URL = Cid + "/" + Cname.replace(/\s+/g, '-')
            history.push('/Movie/' + URL );

        }).catch((err) => {
            console.log("redux ---", err);
        })
    }


    return (
        <>

            <a href={`/Movie/${id}/${name.replace(/\s+/g, '-')}`} onClick={() => GotoMovieDetaile(id, name)}>
                <img className="img-fluid w-100  grid_item" src={coverImageSrc} style={{ borderRadius: "5px" }} />
            </a>


            {/* <div style={{display:"none"}}>

            <Movie  generid={generId} />
        </div> */}

        </>
    )

}
export default MovieCard;

