import { Link, useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";

import { VerifiedTooltip } from "../Atomics/CustomCheckTooltips/CustomCheckTooltips";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {setSelectedTvChannelFilter} from'../../action/TvchannelFilter.js';
import APIUrl from "../../common/Urlcontant";
import { useQuery } from "../Search/search";
import { useDispatch } from "react-redux";
// import  "./SingleChannel.css"


function getCurrntDimensions() {
	return {
		width: window.innerWidth,
		height: window.innerHeight
	}
}
function useDimensionHook() {
	const [currentDimenstion, setCurrentDimenstion] = useState(getCurrntDimensions());

	useEffect(() => {
		const handleResize = () => { setCurrentDimenstion(getCurrntDimensions()) }

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return currentDimenstion;
}
export default function SingleChannelNav({ count, catid, catname }) {
	const { height, width } = useDimensionHook();
	const [category, setcategory] = useState([]);
	const [categoryname, setcategoryname] = useState("Category");
	const query = useQuery()
	const history = useHistory();
	const tvdeatilecatgory = query.get('Cname')
	const [counter, setcounter] = useState(0);
	const dispatch = useDispatch();


	const TvCategory = () => {
		axios.get(APIUrl + `api/tv/category/`).then((res) => {
			try {

				setcategory(res.data.results)
			} catch (error) {

			}
		})
	}

	if (count) {
		axios.get(APIUrl + `api/tv/count-statistic/`).then((res) => {

			setcounter(res.data.total_channels)

		})

	}
	else if (catid && catname) {
		axios.get(APIUrl + `api/tv/channel/?category=${catid}`).then((res) => {
			setcounter(res.data.count)
		})
	}
	else {
		axios.get(APIUrl + `api/tv/count-statistic/`).then((res) => {

			setcounter(res.data.total_channels)

		})

	}



	const channelShort = (item) => {

		history.push(`/channels?sort_by=${item}`)
	}

	const Category = (id, name) => {
			localStorage.setItem('selectedtvfilter', id);
			dispatch(
				setSelectedTvChannelFilter(id)
			).then((res) => {
				// const URL = '/tv/' + ;
				history.push('/channels/'+ id+'/'+ name.replace(/\s+/g, '-'));
	
			}).catch((err) => {
				console.log("redux ---", err);
			})
		
		setcategoryname(name)
	}


	useEffect(() => {

		TvCategory();


	}, [categoryname, tvdeatilecatgory])

	const isMobile = width <= 768
	return (
		<>

			<div className="content-section mt-3 pb-0">
				<div className="filter-section">
					<div className="btn-group season-dropdown float-right d-flex" role="group" aria-label="Basic example">

						
				
						{/* <div className="d-md-none text-white border new_btn dropdown-menu" id="basic-nav-dropdown" ria-haspopup="true" aria-expanded="false"><i class="fa fa-list"  aria-hidden="true"></i></div> */}
						<NavDropdown title="Category" id="basic-nav-dropdown" className="btn navbtn d-none d-md-block"  aria-haspopup="true" aria-expanded="false" style={{ width: 'auto', height: '38px', zIndex: 1 }}>
														{
								category.map((item) => {
									return (
										<>
											<a target="_blank">
												<NavDropdown.Item onClick={() => Category(item.id, item.name)} style={{ backgroundColor: 'black' }}>{item.name}</NavDropdown.Item>
											</a>
										</>
									)
								})
							}
						</NavDropdown>
						<NavDropdown title={<i class="fa fa-list"  aria-hidden="true"></i>} id="basic-nav-dropdown" className="btn navbtn d-md-none"  aria-haspopup="true" aria-expanded="false" style={{ width: 'auto', height: '38px', zIndex: 1 }}>
														{
								category.map((item) => {
									return (
										<>
											<a target="_blank">
												<NavDropdown.Item onClick={() => Category(item.id, item.name)} style={{ backgroundColor: 'black' }}>{item.name}</NavDropdown.Item>
											</a>
										</>
									)
								})
							}
						</NavDropdown>
						<Link onClick={() => channelShort('newest')} className="btn navbtn active ">
							<i className="far fa-clock" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Newest </span>}
						</Link>
						<a onClick={() => channelShort('view')} className="btn navbtn ">
							<i className="far fa-eye" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Views </span>}
						</a>
						{/* <a onClick={()=>channelShort('view')} className="btn navbtn ">
							<i class="fab fa-imdb" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Rating </span>}
						</a> */}
						<a onClick={() => channelShort('title')} className="btn navbtn ">
							<i className="fas fa-sort-alpha-down" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Title </span>}
						</a>

					</div>



					<div className="btn-group season-dropdown mt-3" role="group">
						{/* <h5 className="ml-3 text-white">{!tvdeatilecatgory  ? ` ${categoryname == "Category"  ? counter + " " +"TV" : counter + " " + categoryname} Channel` : `${counter}  ${tvdeatilecatgory} Channel `}</h5> */}
						<h5 className="ml-3 text-white" > {categoryname ? `${categoryname === "Category" && count=="view" || count=="newest" || count=="title" ? `${counter}  Tv Channel ` : `${counter}` + " " + "Channel"}` : `${counter} ${categoryname}`} </h5>
					</div>

				</div>
			</div>
			{/* <div>
				<h3 style={{ fontSize: "24px", margin: "10px" }} >{categoryname == "Category" ? " " : categoryname + " " + "Channels"}</h3>
			</div> */}
		</>
	);
}
