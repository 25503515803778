import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import HomeCard from "./HomeCard";
import Paginate from "../Atomics/Paginate/Paginate";
import "./Homepage.css"

import FatFooter from "../Footer/FatFooter";
import FeaturedSlide from "./FeaturedSlide";
import { useEffect, useState } from "react";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
import { useHistory } from "react-router-dom";
import { setSelectedTvChannel } from '../../action/TvChannelAction.js'
import { setSelectedTvChannelFilter } from '../../action/TvchannelFilter.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faAdjust, faAnkh, faCodeBranch, faEye } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { setSelectedMeta } from "../../action/MetaAction";
import DefaultImg from '../../img/site.jpg'
import { cacheable } from "../../common/Caches";
import HeadTags from "../../common/Helmet";



const HomeVideo = () => {

	const [homecategory, sethomecategory] = useState([])
	const [Channel, setChnnel] = useState([])
	const [Imgurl, setImgurl] = useState([])

	const [Channelname, setChnnelname] = useState()
	const [catId, setcatId] = useState(1);
	const dispatch = useDispatch();
	const history = useHistory();

	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 10,
		slidesToScroll: 1,
		initialSlide: 1,
		swipeToSlide: true,
		autoplay: false,
		autoplaySpeed: 2000,
		nextArrow: false,

		responsive: [

			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 1,
				},
			},
		],
	};
	const HomeCategory = () => {

		Metadispatch('Tv Zinos', 'Watch the world FREE', DefaultImg)

		axios.get(APIUrl + `api/tv/category/`).then((res) => {
			try {

				// sethomecategory(res.data.results)

				// console.log(res.data.results);
				
				let ar = res.data.results
				let value = [15]

				const filteredData = ar.filter(item => !value.includes(item.id));
				setTimeout(() => {
					sethomecategory(filteredData)
				}, 1000)

				// axios.post('webmaster/api.svc/pox/SubmitUrlbatch?apikey=21e9f5425f794752a91e9e72d1c93c52', res.data.results, {
				// 	headers: {
				// 		"Content-Type": "application/json; charset=utf-8",
				// 		}
				// }).then((res) => {
				// 	console.log(res)
				// })


			} catch (error) {

			}
		})
		
	}

	

	const Tvchannel = () => {
		axios.get(APIUrl + `api/tv/landing-category/`).then((res) => {
			let Todolist = res.data.results
			setChnnel(Todolist);
			Todolist?.map((x) => {
				//  ;
				setImgurl(x.channels);

			})
		})
	}

	const Metadispatch = (title, desc, image) => {
		;
		dispatch(
			setSelectedMeta(title, desc, image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}

	// const GotoTVdeatile = (id) => {
	// 	history.push(`/tv?id=${id}`)
	// }

	const GotoTVdeatile = (id, name) => {
		// const decode = name.replaceAll(/\s+/g, '-');
		localStorage.setItem('selectedChannel', id);
		dispatch(
			setSelectedTvChannel(id)
		).then((res) => {
			// const URL = '/tv/' + ;
			history.push('/tv/' + name.replace(/\s+/g, '-'));

		}).catch((err) => {
			console.log("redux ---", err);
		})
	}

	const Category = (id, name) => {
		localStorage.setItem('selectedtvfilter', id);
		dispatch(
			setSelectedTvChannelFilter(id)
		).then((res) => {
			history.push('/channels/' + id + '/' + name.replace(/\s+/g, '-'));

		}).catch((err) => {
			console.log("redux ---", err);
		})

		setcategoryname(name)
	}







	useEffect(() => {
		HomeCategory();
		Tvchannel();
		cacheable(localStorage.setItem('testList', JSON.stringify(homecategory)))
		
	}, [])

	// useEffect(() => {
	// 	Tvchannel();

	// }, [catId])

	return (
		<>
	<HeadTags title='Tv Zinos' image={DefaultImg} description='Watch the world FREE' />


			<div>
				{
					homecategory?.map((item, index) => {
						const channel_index = Channel.findIndex(x => x.category.name == item.name);
						// const img = Channel.findIndex(x=> x.channels.filter((y)=>{y.image?url}))
						// console.log(Channel[0]?.channels[0].image)
						return (
							<>

								<div className="col-sm-12 col-md-12 px-0 " key={index} >
									{
										// Channel[channel_index]?.channels[0].image?.url &&
										<div href="#" className="title-more d-flex justify-content-between">
											<span>{item.name}</span>
											<a onClick={() => Category(item.id, item.name)} style={{ fontSize: "20px" }}> <FontAwesomeIcon icon={faEye} /></a>
										</div>
									}



									<Slider {...settings}  >

										{
											Channel[channel_index]?.channels.map((list) => {

												return (
													<>
														<a href={`/tv/${list.name.replace(/\s+/g, '-')}`}>

															<div className="mx-2"  > <img  onClick={() => GotoTVdeatile(list.id, list.name)} className="" src={list.image?.url} /></div>
														</a>


													</>
												)
											})

										}


									</Slider>

								</div>
							</>
						)
					})
				}

			</div>


			<FatFooter />
		</>
	);
};

export default HomeVideo;
