import { SELECTED_GENER } from "../action/config";

const initialState ={
    selectedGenerId : ''
};

export default function GenerReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_GENER : 
        return{
            ...state,
            selectedGenerId : action.selectedgener
        }
        default :
        return state
    }
}