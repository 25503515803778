import React, { useEffect, useState } from "react";
import { Container, NavDropdown } from "react-bootstrap";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { useQuery } from "../Search/search";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
import VideoPlayer from "../../common/Videoplayer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import './SeriesDetaile.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useHistory } from "react-router-dom";
import HeadTags from "../../common/Helmet";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTvChannel } from "../../action/TvChannelAction";
import { setSelectedMeta } from "../../action/MetaAction";
import { cacheable } from "../../common/Caches";


const SeriesDetaile = () => {

	const [list, setList] = useState({})
	const [country, setcountry] = useState();
	const [flag, setflag] = useState();
	const SeriesId = useSelector((state) => state.ChannelObj.selectedChannelId || localStorage.getItem('selectedChannel'));
	const [gener, setgener] = useState();
	const [cast, setcast] = useState([])
	const [trailer, settrailer] = useState('');
	const [model, setmodel] = useState(false)
	const [VideoUrl, setVideoUrl] = useState('')
	const [KeyWord, setkeyWord] = useState([])
	const [season, setSeason] = useState([])
	const [Episode, setEpisode] = useState([])
	const [specialEpisode, setspecialEpisode] = useState()
	const [seaName, setseaName] = useState()
	let hide
	const dispatch = useDispatch();

	let LanguageFullName = '';
	const getLanguage = (code) => {
		const lang = new Intl.DisplayNames(['en'], { type: 'language' });
		return lang.of(code);
	}
	const query = useQuery();
	const history = useHistory();

	const detaile = () => {

		axios.get(APIUrl + `api/tv/get-series/${SeriesId}/`).then((res) => {

			const response = res.data.series
			setList(res.data.series)
			Metadispatch(res.data.series.name, res.data.series.overview, res.data.series.backdrop_image)

			response.production_countries.map((item) => {
				setcountry(item.name)
				setflag(item.flag)
			})
			const arr = [];

			response.genres.filter((item) => {
				arr.push(item.name + " ")
			})
			response.original_language = getLanguage(res.data.series.original_language)
			setgener(arr)

		})

	}

	const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(`${title} | Watch`+ ' ' + `${title} Free Online - TVzinos`, desc , image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}

	const  ActorDetaile = (Aid , Aname) =>{
		// history.push(`/Actor?id=${id}`)
		
			localStorage.setItem('selectedChannel', Aid);
			dispatch(
				setSelectedTvChannel(Aid)
			).then((res) => {
				const URL = Aid + "/" + Aname.replace(/\s+/g, '-')
				history.push('/Actor/' + URL );
	
			}).catch((err) => {
				console.log("redux ---", err);
			})
		
	   }
	const TrailerHandler = async () => {

		await axios.get(APIUrl + `api/tv/series/${SeriesId}/videos/?type=Trailer`).then((res) => {

			settrailer(res.data.videos[0]?.key);
			hide = res.data.videos[0];
			setmodel(true)
			setVideoUrl(`https://www.youtube.com/embed/${res.data.videos[0]?.key}`)
		})
	}

	const Cast = () => {
		axios.get(APIUrl + `api/tv/series/${SeriesId}/credits/`).then((res) => {
			setcast(res.data.cast)
		})
	}

	const Keyword = () => {
		axios.get(APIUrl + `api/tv/series/${SeriesId}/keywords/`).then((res) => {
			setkeyWord(res.data.keywords)
		})
	}

	const Seasons = () => {
		axios.get(APIUrl + `api/tv/series/${SeriesId}/seasons/`).then((res) => {
			setSeason(res.data.seasons)

			setspecialEpisode(res.data.seasons[0]?.id)


		})
	}

	const 
	SeasonsEpisode = (Id, name) => {


		if (!Id) {
			axios.get(APIUrl + `api/tv/series/season/${specialEpisode}/episodes/`).then((res) => {
				setseaName(name)
				setEpisode(res.data.episodes)

			})
		}
		else {
			axios.get(APIUrl + `api/tv/series/season/${Id}/episodes/`).then((res) => {
				setseaName(name)
				setEpisode(res.data.episodes)


			})
		}
	}

	const EpisodeDetaile = (Eid , Ename) => {
		// history.push(`/episodes?Id=${Eid}`)
	
			localStorage.setItem('selectedChannel', Eid);
			dispatch(
				setSelectedTvChannel(Eid)
			).then((res) => {
				const URL = Eid + "/Episode-" + Ename
				history.push('/episodes/' + URL );
	
			}).catch((err) => {
				console.log("redux ---", err);
			})
		
	}


	useEffect(() => {
		detaile();
		Cast();
		Keyword();
		Seasons();
		cacheable(localStorage.setItem('testList',JSON.stringify(list)))
		cacheable(localStorage.setItem('testList',JSON.stringify(season)))
		cacheable(localStorage.setItem('testList',JSON.stringify(cast)))
		cacheable(localStorage.setItem('testList',JSON.stringify(KeyWord)))
		cacheable(localStorage.setItem('testList',JSON.stringify(Episode)))


	}, [])

	useEffect(()=>{
		SeasonsEpisode();

	},[specialEpisode])
s


	return (

		<ContentWrapper className="single-channel-page">
			
<HeadTags title={list.name} image={list.backdrop_image} description={list.overview} />
			<div className="col-md-12">
				<div className="movie-header" style={{ backgroundImage: `url(${list.backdrop_image})` }}>
					<div className="movie-bigtitle">
						<div className="movie-maintitle"><h2>{list.name}</h2></div>
						<div className="movie-smalltitle">
							<p className="movie-lefttitle"> {list.number_of_seasons} Seasons |  {country}   | {list.original_language} </p>
							<div className="movie-righttitle">
								<span>{gener} | {parseFloat(list.vote_average).toFixed(2)}/10</span> <span className="imdb">  IMDB  </span>
								<img style={{ width: '50px', height: '50px' }} src={flag} ></img>
							</div>
						</div>
					</div>
					{/* <div className="header-buttons"><button className="open-login"> <i className="far fa-heart" aria-hidden="true"></i></button></div> */}
				</div>
			</div>

			<div className="col-md-12">

				<div>
					{
						// trailer.length ?
						<a ><button id="trailer-btn" onClick={() => TrailerHandler()} className="btn play-btn dark-mode "><span className="fa fa-bullhorn" aria-hidden="true"></span> Watch Trailer</button></a>
						// : ""  	
					}
					<a target="_blank" href={`https://rarbggo.org/torrents.php/search/?search=${list.name}&category=TV Shows`}>	<button id="trailer-btn" className="btn play-btn dark-mode "><span className="fa fa-download" aria-hidden="true"></span> Download</button></a>
				</div>
			</div>

			<div className="col-md-12 padding-top-20">
				<div className="movie-section-title">Overview</div>
			</div>
			<div className="col-md-12">
				<p className="movie-setion-content">
					{list.overview}
				</p>
			</div>

			<div class="sharethis-inline-reaction-buttons"></div>


			<div className="col-md-12">
				<div className="d-flex justify-content-between align-items-center">
					<div><p className=" mt-3 text-white series_text">{seaName ? seaName : "Special"}  </p></div>
					<div>
						<NavDropdown title="Seasons" className="btn navbtn" aria-haspopup="true" aria-expanded="false" >
							{
								season.map((item) => {
									return (
										<NavDropdown.Item onClick={() => SeasonsEpisode(item.id, item.name)} >{item.name}</NavDropdown.Item>
									)
								})
							}
						</NavDropdown>
					</div>
				</div>
			</div>


			<Row>
				{
					Episode?.map((item) => {
						return (
							<Col xl={3} sm={4} xs={6} className="p-2" >
								<div className="cu_bg h-100" onClick={() => EpisodeDetaile(item.episodeID , item.episode_number)}>
									<img className="h-100" src={item.still_image ? item.still_image : list.backdrop_image} />
									<div className="uper" >
										<span>
											<h4 >Episode {item.episode_number}</h4>
											<p>{item.overview ? item.overview : list.overview}  </p>
										</span>
									</div>
								</div>
							</Col>
						)
					})
				}
			</Row>

			{
				KeyWord.length ?
					<div className="col-md-12 pt-3">
						<div className="d-flex align-items-center cut_btn">
							<h6 className="fs-5 text-white mb-0 btn_cu"><span><FontAwesomeIcon icon={faTag} /></span>Tags :</h6>
							<div className="d-flex flex-wrap align-items-center oth_btn">
								{

									KeyWord?.map((item) => {
										return (
											<div className="rounded-pill text-white bg-white py-1 px-3 d-inline block ">{item.name}</div>
										)
									})
								}
							</div>
						</div>

					</div>
					: " "
			}

			<div className="col-md-12 padding-top-20">
				<div className="movie-section-title">Cast</div>
			</div>

			<div className="flix-carousel-actor">
				<div className="flix-scroll-x">
					{
						cast?.map((item) => {
							return (
								<>
									{
										item.profile_image &&
										<a href={`/Actor/${item.creditID}/${item.name.replace(/\s+/g, '-')}`} onClick={()=>ActorDetaile(item.creditID , item.name)} className="actor">

											<img src={item.profile_image} />


											<span>{item.original_name}</span>
											<label>{item.character}</label>
										</a>
									}
								</>
							)
						})
					}

				</div>
			</div>


			<VideoPlayer modal={model} setModal={setmodel} VideoUrl={VideoUrl} />



		</ContentWrapper>
	)
}

export default SeriesDetaile;