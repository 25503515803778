import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import ActorsCard from "./ActorsCard";
import Paginate from "../Atomics/Paginate/Paginate";

import FatFooter from "../Footer/FatFooter";

const ChannelVideos = ({src , name , id}) => {
	// console.log(src);
	return (
		<>
					<div className="col-4 col-sm-3 col-md-2 col-lg-1">
				<div className="video-block section-padding">

					{/* <Col md={12}>
						<SectionHeader   heading="Read 6753 Movie magazines for free!" />
					</Col> */}

					
						<ActorsCard
							name={name}
							coverImageSrc={src}
							id={id}
							
						/>
					
						
					</div>
			</div>
			{/* <Paginate /> */}
			{/* <FatFooter /> */}
		</>
	);
};

export default ChannelVideos;
