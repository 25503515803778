import { Link } from "react-router-dom";
import "./Sidebar.css";

import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import NavItem from "./NavItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faHome,
	faUsers,
	faBars,
	faMusic,
	faNewspaper,
	faChild,
	faLaugh,
	faBusinessTime,
	faMountain,
	faShoppingBag,
	faVideo,
	faHistory,
	faFootballBall
} from "@fortawesome/free-solid-svg-icons";

const Sidebar = ({ props }) => {
	const sidebarClass = props.showMenu
		? "sidebar navbar-nav  toggled"
		: "sidebar navbar-nav ";
	return (
		<>
			<ul className={sidebarClass}>
				<NavItem href="/" label="Home" active />
				<NavItem href="/channels" label="Tv Channels" />
				<NavItem href="/Movies" label="Movies" />
				<NavItem href="/Series" label="TV Series" />
				<NavItem href="/genre" label="Genres" />
				<NavItem href="/Actors" label="Actors" />
				<NavItem href="/World" label="Country" />
				<NavItem href="/Birthday" label="Birthday" />
				<NavItem href="https://onebas.com/" label="Music" />
				<NavItem href="https://www.magazinos.com/" label="Magazine"  />
				<NavItem href="https://www.razinos.com/" label="Redio"  />
			</ul>
		</>
	);
};

export default Sidebar;
