import React, { useEffect, useRef } from "react";
const $ = window.jQuery;


const HomeCard = ({ name, href = '#', coverImageSrc  }) => {

    coverImageSrc = "mgz/movieImg.jpg"


    return (
        <>

            <a className="poster col-4 col-md-2 col-xl-2 col-xxl-2" href="/single-channel">
                <img className="img-fluid w-100" src={coverImageSrc} style={{ borderRadius: "5px" }} />
            </a>

        </>
    )

}
export default HomeCard;

