import "./SingleChannel.css";
import Container from "react-bootstrap/Container";
import FatFooter from "../Footer/FatFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useEffect, useState } from "react";
import { useQuery } from "../Search/search";
import axios from "axios";
import SingleChannel from "../SingleChannel/SingleChannel";
import SingleChannelHero from  "../SingleChannel/SingleChannelHero"
import APIUrl from "../../common/Urlcontant";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMeta } from "../../action/MetaAction";
import DefaultImg from '../../img/site.jpg'
import HeadTags from "../../common/Helmet";

const Movie = () => {
	let query = useQuery();
	const dispatch = useDispatch();
	const [list, setList] = useState({})
	const [Country, setCountry] = useState('')
	const [flag, setflag] = useState('')
	const [genre, setgenre] = useState([])
	const [ genId , setgenId ] = useState(query.get('genreID'));
	const GenerId = useSelector((state) => state.GenerObj.selectedGenerId );
	const prefix = 'https://image.tmdb.org/t/p/original/'
	const id = query.get('id');
	const genreID = query.get('genreId');

	
	const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(`Tvzinos | ${title}`, desc, image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		Metadispatch('Movies', 'Watch the world FREE ', DefaultImg)

	
	}, [])

	return (

		<ContentWrapper className="single-channel-page">
			<Container fluid>
			<HeadTags title='Tv Zinos | Movie' image={DefaultImg} description='Watch the world FREE' />

					
						{/* <SingleChannelNav /> */}

						<ChannelVideos GenerId={GenerId} />
					
				
					
			
			</Container>
		</ContentWrapper>



	);
};

export default Movie;
