import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSelectedTvChannel } from '../../action/TvChannelAction.js'
const $ = window.jQuery;


const ActorsCard = ({ name, href = '#', coverImageSrc, id }) => {

    const history = useHistory()
    const dispatch = useDispatch();


   const  ActorDetaile = (Aid , Aname) =>{
    history.push(`/Actor?id=${id}`)
    
        localStorage.setItem('selectedChannel', Aid);
        dispatch(
            setSelectedTvChannel(Aid)
        ).then((res) => {
            const URL = Aid + "/" + Aname.replace(/\s+/g, '-')
            history.push('/Actor/' + URL );

        }).catch((err) => {
            console.log("redux ---", err);
        })
    
   }

    return (
        <>
            {/* <div className="actor-large">
                <a href="/brand">
                    <img src={coverImageSrc} />
                    <h6 style={{ color: 'white', padding: 0, marginTop: '15px', textAlign: 'center' }}>
                        {name}
                    </h6>
                </a>
            </div> */}
            
            <a className="poster "  href={`/Actor/${id}/${name.replace(/\s+/g, '-')}`} onClick={()=>ActorDetaile(id , name)}>
                <img className="img-fluid w-100" src={coverImageSrc} style={{ borderRadius: "5px" }} />
                <h6 style={{ color: 'white', padding: 0, marginTop: '15px', textAlign: 'center',fontSize:"15px" }}>
                        {name}
                    </h6>
            </a>
        </>
    )

}
export default ActorsCard;

