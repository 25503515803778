import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState } from "react";
import Spinner from 'react-bootstrap/Spinner';
import './player.css'

const VideoPlayer = ({ modal, setModal, VideoUrl }) => {

    const [videoLoading, setVideoLoading] = useState(true);

    const spinner = () => {
        setVideoLoading(!videoLoading);
    };

    useEffect(() => { }, [VideoUrl])

    return (
        <div className="App">

            {modal ? (
                <section className="modal__bg">
                    <div className="modal__align">
                        <div className="modal__content" modal={modal}>

                            {
                                VideoUrl ? <FontAwesomeIcon style={{ fontSize: '40px', cursor: "pointer", position: "absolute", zIndex: "999", top: "100" ,backgroundColor:"white" , borderRadius:"60px" }} icon={faTimesCircle} onClick={() => setModal(false)} /> : <h3 color="white" b>Trailer Not Found</h3>
                            }
                            {/* </button> */}
                            {/* <FontAwesomeIcon className="modal__close"  onClick={() => setModal(false)} icon="close" /> */}
                            <div className="modal__video-align">
                                {videoLoading ? (
                                    <div className="modal__spinner">
                                        <Spinner animation="border" role="status">
                                            <span className="visually-hidden"></span>
                                        </Spinner>
                                    </div>
                                ) : null}
                                <iframe
                                    className="modal__video-style"
                                    onLoad={spinner}
                                    loading="lazy"
                                    width="800"
                                    height="500"
                                    src={VideoUrl}
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </section>
            ) : null}
        </div>

    )


}

export default VideoPlayer;
