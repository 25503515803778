import React, {useEffect, useRef} from "react";
const $ = window.jQuery;


const SingleCard = ({name, href='#', src}) => {   
    return (
        <>  
            <a className="channeldetailslide" href="#">
                <img src={src}/>
            </a>            
        </>
    )
}
export default SingleCard;

