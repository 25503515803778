import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import APIUrl from "../../common/Urlcontant";
import { setSelectedCountry } from "../../action/countryAction";
import { useDispatch } from "react-redux";
const $ = window.jQuery;


const CountriesCard = ({ coverImageSrc,count ,id,name }) => {
    const history = useHistory();
    const dispatch = useDispatch()
    
    
    const CountryChannel =() => {
			localStorage.setItem('selectedcountry', id);
			localStorage.setItem('selectedcountryFlag', coverImageSrc);
			localStorage.setItem('selectedcountryName', name);
			dispatch(
				setSelectedCountry(id  , coverImageSrc , name)
			).then((res) => {
				// const URL = '/tv/' + ;
				history.push('/Country/'+ name.replace(/\s+/g, '-'));
	
			}).catch((err) => {
				console.log("redux ---", err);
			})
		

    }
    
   

    return (
        <>

       
            
           
            
            <Link  className="sub_chanel position-reletive " onClick={()=>CountryChannel()} >
                <img src={coverImageSrc} className="position-relative" />
                <span className="position-absolute start-0 top-0">{count}</span>

            </Link>

        



        </>
    )

}
export default CountriesCard;

