import "./SingleChannel.css"
import SingleCard from "./SingleCard";

import { Link, Redirect, useHistory } from "react-router-dom";
import axios from "axios";

import APIUrl from "../../common/Urlcontant";
import { useEffect, useState } from "react";
import { setSelectedTvChannel } from '../../action/TvChannelAction.js'

import url from '../../img/tv.jpg'
import HeadTags from "../../common/Helmet";
import { useDispatch, useSelector } from "react-redux";
import MetaTag from "../../common/MetaTag";
import { setSelectedMeta } from "../../action/MetaAction";
import { cacheable } from "../../common/Caches";
export default function SingleChannelHero({ id }) {



	const [list, setlist] = useState({})
	const [image, setimage] = useState({})
	const [categoryname, setcategoryname] = useState([]);
	const [categoryId, setcategoryId] = useState([]);
	const [SimilorTV, setSimilorTV] = useState([]);
	const [dispachId, setdispachId] = useState(id)
	const ChannelId = useSelector((state) => state.ChannelObj.selectedChannelId || localStorage.getItem('selectedChannel'));
	const [Id, setId] = useState();
	// const navigate = useNavigate();
	const history = useHistory();
	const dispatch = useDispatch()
	let SimilarArray = []





	const TvChannelDetailes = async () => {
		await axios.get(APIUrl + `api/tv/channel/${ChannelId}`).then((res) => {
			setlist(res.data);
			setimage(res.data.image)
			setcategoryname(res.data.category[0]?.name)
			setcategoryId(res.data.category[0]?.id)
			Metadispatch(res.data.name, res.data.description ? res.data.description : 'Watch the world FREE ' , res.data.image?.url)


		})
	}
	const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(`${title} | Watch`+ ' ' + `${title} Free Online - TVzinos`, desc , image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}

	const Smiliar = (id) => {
		// setTimeout(()=>{
		axios.get(APIUrl + `api/tv/channel/?category=${categoryId}&count=10&page=1`).then((res) => {
			// setSimilorTV(res.data.results);
			const arr = res.data.results

			SimilarArray = arr.filter((item, index) => index < 10)
			setSimilorTV(SimilarArray);


		})
		// },5000)
	}

	const GotoTVdeatile = (id, name) => {
		// Metadispatch(name, list.description, list.image?.url)

		dispatch(
			setSelectedTvChannel(id)
		).then((res) => {
			// const URL = '/tv/' + ;
			history.push('/tv/' + name.replace(/\s+/g, '-'));

		}).catch((err) => {
			console.log("redux ---", err);
		})
		localStorage.setItem('selectedChannel', id)
	}





	const playChannel = (id, name) => {
		localStorage.setItem('selectedChannel', id);
		dispatch(
			setSelectedTvChannel(id)
		).then((res) => {
			// const URL = '/tv/' + ;
			history.push('/Player/' + name.replace(/\s+/g, '-'));

		}).catch((err) => {
			console.log("redux ---", err);
		})

	}
	const Category = (name, id) => {
		history.push(`/channels?id=${id}&name=${name}`)
	}


	useEffect(() => {
		// Metadispatch(list.name, list.description, list.image?.url)

		TvChannelDetailes();
		Smiliar();
		axios.post(APIUrl + `api/tv/channel-view/${id}/`)
		cacheable(localStorage.setItem('testList',JSON.stringify(list)))
		cacheable(localStorage.setItem('testList',JSON.stringify(SimilorTV)))


	}, [categoryId ,ChannelId])

	// useEffect(()=>{},[])

	// 	useEffect(()=>{
	// setdispachId(ChannelId)
	// 	},[ChannelId])
	return (
		<>


			{/* <div className="col-md-12">
				<div className="movie-header" style={{ backgroundImage: `url(${image ? image.url : url})` }} ></div>
				<div className="sub_image">
					<div style={{ backgroundImage: `url(${image ? image.url : url})`, height: "200px", width: "350px", }} className="d-inline-block other_img"> </div>
				</div>

				<div className="movie-title position-absolut ">{list.name}</div>
				<div className="movie-infos position-absolut"> &nbsp;&nbsp; {categoryname ? categoryname : ''} </div>
			</div> */}

			{/* <HeadTags title={list.name} image={image ? image.url : url} description={list.description} /> */}

			<div className="col-md-12">
				<div className="movie-header-bg" style={{ backgroundImage: `url(${image ? image.url : url})` }}>
					<div className="movie_header">
						<div className="movie_title text-white">{list.name}</div>
						<Link onClick={() => Category(categoryname, categoryId)} className="movie-infos ms-3 text-white"  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{categoryname ? categoryname : ''}</Link>
					</div>
					<img src={`${image ? image.url : url}`} className="channel_logo"></img>
				</div>
			</div>

			<div className="col-md-12">
				<button id="trailer-btn" className="btn play-btn" onClick={() => playChannel(list.id, list.name)}><span class="fa fa-play" aria-hidden="true"></span> &nbsp; Play Channel</button>
				{list.website ? <a href={list.website} target="_blank" id="trailer-btn" className="btn play-btn" ><span class="fas fa-globe-americas" aria-hidden="true"></span> Visit website</a> : ""}
			</div>
			{
				list.description &&
				<>

					<div className="col-md-12 padding-top-20">
						<div className="movie-section-title">Overview</div>
					</div>
					<div className="col-md-12">
						<p className="movie-setion-content">
							{list.description}
						</p>
					</div>
				</>
			}

			<div class="sharethis-inline-reaction-buttons"></div>

			<div class="col-md-12 padding-top-20">
				<div class="movie-section-title">Similar TV:</div>
			</div>

			<div className="similar_con">
				{
					SimilorTV.map((item) => {

						return (
							<a onClick={() => GotoTVdeatile(item.id, item.name)} target="_blank" >
								<img src={item.image?.url} />
							</a>
						)
					})

				}
			</div>



		</>
	);
}
