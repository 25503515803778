import "./Homepage.css";
import Container from "react-bootstrap/Container";

import TopMobileSearch from "./TopMobileSearch";
import TopCategory from "./TopCategory";
import VideoBlock from "./VideoBlock";
import PopularChannels from "./PopularChannels";

import FatFooter from "../Footer/FatFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import HomeVideo from "./HomeVideo";
import { useEffect } from "react";
import FeaturedSlide from "./FeaturedSlide";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSelectedMeta } from "../../action/MetaAction";

const Homepage = () => {
	const dispatch = useDispatch();
	const history = useHistory();
		
	// const Metadispatch = ({title, desc, image}) => {
	// 	dispatch(
	// 		setSelectedMeta(title, desc, image)
	// 		).then((res) => {
	// 			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
	// 			console.log(res);
	// 		})
	// 	}
		useEffect(() => {
			// Metadispatch('Tv Zinos' , 'Its All About Tv Channel' ,'https://preview.redd.it/v1fvin01ynv51.jpg?auto=webp&s=dc5339071eb58f278ba14aab615b24b8ddbfd2dc' )
			window.scrollTo(0, 0)
		})
	return (
		<>
			<div style={{ background: '#232323 !important' }}>
				<ContentWrapper >
					<Container fluid>
						<TopMobileSearch />						
						<HomeVideo />
					</Container>
				</ContentWrapper>
			</div>
		</>
	);
};

export default Homepage;
