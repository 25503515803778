import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import MovieCard from "./MovieCard";
import Paginate from "../Atomics/Paginate/Paginate";
import "./SingleChannel.css"

import FatFooter from "../Footer/FatFooter";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useQuery } from "../Search/search";
import APIUrl from "../../common/Urlcontant";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { cacheable } from "../../common/Caches";

const ChannelVideos = ({GenerId}) => {
	let query = useQuery();
	const [list, setlist] = useState([])
	const prefix = 'https://image.tmdb.org/t/p/original/'
	const apiKey = '8c8960e929b3daeea44acef93cd6fdf5';
	const [page, setPage] = useState(1);
	const{height,width}=useDimensionHook();
	const FilterId = useSelector((state) => state.FilterObj.selectedFilterId );
	const [ Counter , setcounter] = useState()
	const [ genre , setGenre] = useState([]);
	const dispatch = useDispatch();
	const isMobile = width <=768 
	const[Sort_by,setSort_by] = useState('');
	const history = useHistory();

	const [totalRecords, setTotalRecords] = useState(0);
	let Api = '';

	const prevCountRef = useRef();
	if (query.get('sort_by')) {

		Api = APIUrl +  `api/tv/movie/?sort_by=${query.get('sort_by')}&page=${page}&size=50`

	}
	else if(GenerId){
		Api  = APIUrl + `api/tv/movie/?page=${page}&size=50&genre=${GenerId}`
		
	}
	
	else{
		Api = APIUrl + `api/tv/movie/?page=${page}&size=50`
	}

	const LoadMore = async (isReset) => {

		await axios.get(Api).then((res) => {
			try {
				setTotalRecords(res.data.count)
				if (isReset)
					setlist(res.data.results);

				else
					setlist([...list, ...res.data.results]);

			} catch (error) {

			}
		})
	}

	
function getCurrntDimensions() {
	return {
		width: window.innerWidth,
		height: window.innerHeight
	}
}
function useDimensionHook(){
	const[currentDimenstion,setCurrentDimenstion] = useState(getCurrntDimensions());

	useEffect(()=>{
		const handleResize=()=>{setCurrentDimenstion(getCurrntDimensions())}

		window.addEventListener('resize', handleResize)
		
		return ()=> window.removeEventListener('resize', handleResize)
	},[])

	return currentDimenstion;
}

const ShortingHandler = async ( name) => {
	history.push(`/Movies/?sort_by=${name}`)
	
        // history.push(`/Movies?genreID=${id}`)
       
            // localStorage.setItem('selectedFilter', id);
            // dispatch(
            //     setSelectedFilter(id)
            // ).then((res) => {
            //     const URL = id + "/" + name
            //     history.push('/Movies/' + URL );
    
            // }).catch((err) => {
            //     console.log("redux ---", err);
            // })     
    
	}

	// const Moviecard = (item) => {
	// 	history.push(`/Movies?id=${item.movieID}`)
	// 	// history.push(`/Movies?id=${query.get('genreID')}`)
	// }
	
	const counter = async()=>{
		await axios.get(APIUrl +`api/tv/count-statistic/`).then((res)=>{
				setcounter(res.data.tmdb_total_movies)
		})
	}


	useEffect(() => {
		LoadMore();
		counter();
		cacheable(localStorage.setItem('testList',JSON.stringify(list)))
	}, [page])

	useEffect(() => {
		LoadMore(true)
	}, [query.get('sort_by')])



	return (
		<>

<div className="content-section">
				<div className="filter-section1">
					

					<div className="d-flex align-items-center ">
					
					<h5 style={{fontSize:"18px" , marginBottom:"0px" , paddingLeft:"10px"}}>{Counter} movies</h5>


					</div>
					<div className="btn-group season-dropdown float-right" role="group" aria-label="Basic example">
				
						<Link to="/Movies"  className="btn navbtn active ">
							<i className="far fa-clock" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Newest</span>}
						</Link>
					
						<a  onClick={()=>ShortingHandler('imdb')} className="btn navbtn ">
							<i className="fab fa-imdb" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Rating</span>}
						</a>
						
						<a  onClick={()=>ShortingHandler('title')} className="btn navbtn ">
							<i className="fas fa-sort-alpha-down" aria-hidden="true"></i> 
							{!isMobile && <span className="for-desktop" > Title</span>}
						</a>
						<a onClick={()=>ShortingHandler('year')} className="btn navbtn ">
							<i className="fas fa-calendar-alt" aria-hidden="true"></i>
							{!isMobile && <span className="for-desktop"> Year</span>}
						</a>

					</div>
				</div>
			</div>
			<div className="video-block section-padding">
				{/* <Row className="content-section"> */}
				{/* <Col md={12}>
						<SectionHeader   heading="Read 6753 Movie magazines for free!" />
					</Col> */}
					
				<div class="grid">
					{
						list.map((item) => {
							

							// <div className="grid_item">	
							return (
								(
									item.poster_image &&
									<MovieCard
										id={item.movieID}
										coverImageSrc={prefix + item.poster_image}
										generId={query.get('genreID')} 
										name = {item.title}
										
									/>)


							)
							// </div>

						})


					}
				</div>
				{/* </Row> */}
			</div>
			<div style={{ textAlign: "center", paddingBottom: "10px" }}>
				{
					list.length < totalRecords ? <button className="loadmore" onClick={() => setPage((page) => page + 1)}>Load More...</button> : <h2>Please search movie name</h2>
				}

			</div>

			{/* <Paginate /> */}
			<FatFooter />
		</>
	);
};

export default ChannelVideos;
