import "./FatFooter.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMobile,
	faGlobe,
	faEnvelope,
	faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const FatFooter = () => {

	const year = new Date().getFullYear();
	return (
		<>
			<footer className="sticky_footer" style={{bottom:0, position:"sticky"}}>
				<div>
					<div className="row no-gutters footer_space">
						<div className="col-lg-6 col-sm-6">
						<p class="mt-1 mb-0" style={{textAlign:"left"}}> <span style={{opacity:"0.6"}}> &copy; Copyright {year}</span> <strong style={{color:'white'}}>TV zinos</strong><span style={{opacity:"0.6"}}>. All Rights Reserved </span><br/>
							<small class="mt-0 mb-0"> <span style={{opacity:"0.6"}} > Powered by </span><Link style={{color:"#FF0000 "}} target="_blank" href="https://www.stormvik.com/"> Stormvik </Link>
							</small>
							</p>
						</div>
						<div className="col-lg-6 col-sm-6 text-right">
							<div className="app">
							<a href="https://play.google.com/store/apps/details?id=com.Stormvik.tvzinos"><img alt="" src="img/google.png"/></a>
							<a href="#"><img alt="" src="img/apple.png"/></a>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default FatFooter;
