import { SELECTED_FILTER } from "../action/config";

const initialState ={
    selectedFilterId : ''
};

export default function FilterReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_FILTER : 
        return{
            ...state,
            selectedFilterId : action.selectedfilter
        }
        default :
        return state
    }
}