import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {setSelectedGener} from "../../action/GenerAction"
import APIUrl from "../../common/Urlcontant";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import './geners.css'
import { useDispatch } from "react-redux";
import DefaultImg from '../../img/site.jpg'
import { setSelectedMeta } from "../../action/MetaAction";
import { cacheable } from "../../common/Caches";

const Genres = () => {

    const [List, setList] = useState([]);
    const dispatch = useDispatch();

    const history = useHistory();
    const GenersHandler = async () => {
        axios.get(APIUrl + `api/tv/genre/`).then((res) => {
            setList(res.data.genres)
            Metadispatch('Genres', 'Watch the world FREE ', DefaultImg)

        })
    }
    const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(`Tvzinos | ${title}`, desc, image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}  

    const RelatedGeners = (id , name) => {
        // history.push(`/Movies?genreID=${id}`)
       
            localStorage.setItem('selectedChannel', id);
            dispatch(
                setSelectedGener(id)
            ).then((res) => {
                const URL = id + "/" + name.replace(/\s+/g, '-')
                history.push('/Movies/' + URL );
    
            }).catch((err) => {
                console.log("redux ---", err);
            })
        
    
    }

    useEffect(() => {

        GenersHandler();
        cacheable(localStorage.setItem('testList',JSON.stringify(List)))

    }, [])

    return (
        <>
            <ContentWrapper>
                <Container fluid>
                    <div class="geners_box row">
                        {
                            List?.map((item_1) => {
                                return (

                                    <div key={item_1} class="h-100 w-100 col-xl-2 col-md-3 col-4 px-1" onClick={() => RelatedGeners(item_1.genreID , item_1.name)}>
                                        <div><img className="box_img " src={item_1.image} /></div>
                                        <div className="genes_text">{item_1.name}</div>
                                    </div>
                                )
                            })
                        }
                    </div>




                </Container>
            </ContentWrapper>
        </>
    )

}

export default Genres;