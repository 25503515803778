
import SingleCard from "./SingleCard";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import APIUrl from "../../common/Urlcontant";
import VideoPlayer from "../../common/Videoplayer";
import Actors from "../Actors/Actors";
import { useQuery } from "../Search/search";
import "./SingleChannel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag } from "@fortawesome/free-solid-svg-icons";
import HeadTags from "../../common/Helmet";
import { setSelectedTvChannel } from "../../action/TvChannelAction";
import { useDispatch, useSelector } from "react-redux";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { Container } from "react-bootstrap";
import { setSelectedMeta } from "../../action/MetaAction";
import { cacheable } from "../../common/Caches";
import DefaultImg from '../../img/site.jpg'
export default function SingleChannelHero({
	// src,
	// title,
	// year,
	// country,
	// rating,
	// language,
	// flag,
	// genre,
	// duration,
	// overview,
	// id,
	// genreID
}) {
	const [trailer, settrailer] = useState('')
	const ChannelId = useSelector((state) => state.ChannelObj.selectedChannelId ||  localStorage.getItem('selectedChannel'));
	const [cast, setcast] = useState([])
	const [list, setList] = useState({})
	const [model, setmodel] = useState(false)
	const [VideoUrl, setVideoUrl] = useState('')
	const [Gener , setGener] = useState([]);
	const [KeyWord, setkeyWord] = useState([])
	const [Country, setCountry] = useState('')
	const [flag, setflag] = useState('')
	const [genre, setgenre] = useState([])
	const prefix = 'https://image.tmdb.org/t/p/original/'
	const dispatch = useDispatch();
	const history = useHistory();

	let LanguageFullName ='';
	const getLanguage = (code) => {
		const lang = new Intl.DisplayNames(['en'], { type: 'language' });
		return lang.of(code);
	  }
	  const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(`${title} | Watch`+ ' ' + `${title} Free Online - TVzinos`, desc, image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}


	const Moviehandler = async (e) => {
	
			await axios.get( APIUrl + `api/tv/get-movie/${ChannelId}`).then((res) => {
				try {
					if (res) {
						var data = res.data.movies
						data.production_countries.forEach((item)=>{ 
							setCountry(item.name)
							setflag(item.flag)
							// item.LanguageFullName = getLanguage(item.original_language);
						});
						const arry =[]

						
						data.genres.filter((item)=>{
							arry.push(item.name + "  ")
						});
						data.original_language = getLanguage(res.data.movies.original_language)
							// el.LanguageFullName = getLanguage(el.original_language);
						
						
						
						setgenre(arry)
						setList(res.data.movies)
						Metadispatch(res.data.movies.original_title , res.data.movies.overview ? res.data.movies.overview : 'Watch the world FREE ' , prefix+res.data.movies.backdrop_image)

						
					}

				} catch (error) {

				}
			})
		
		

	};

	const ActorDetaile = (Aid, Aname) => {
        localStorage.setItem('selectedChannel', Aid);
        dispatch(
            setSelectedTvChannel(Aid)
        ).then((res) => {
            const URL = Aid + "/" + Aname.replace(/\s+/g, '-')
            history.push('/Actor/' + URL );

        }).catch((err) => {
            console.log("redux ---", err);
        })
    }


	useEffect(() => {
		window.scrollTo(0, 0)
		Moviehandler()
	}, [cast])



	const TrailerHandler = async () => {

		await axios.get(APIUrl + `api/tv/movie/${ChannelId}/videos/?type=trailer`).then((res) => {

			settrailer(res.data.videos[0]?.key);
			setmodel(true)
			setVideoUrl(`https://www.youtube.com/embed/${res.data.videos[0]?.key}`)
		})
	}

	const CoastHandler = async () => {
		await axios.get(`${APIUrl}api/tv/movie/${ChannelId}/credits/`).then((res) => {

			setcast(res.data.cast);


		})
	}


	const Keyword = () => {
		axios.get(APIUrl + `api/tv/movie/${ChannelId}/keywords/`).then((res) => {
			setkeyWord(res.data.keywords)
		})
	}

	useEffect(() => {
		CoastHandler();
		Keyword();
		cacheable(localStorage.setItem('testList',JSON.stringify(list)))
	}, [])

	return (

		<ContentWrapper>
			<Container fluid>

			<HeadTags title={list.original_title} image={DefaultImg} description={list.overview} />
		<>



			<div className="col-md-12 px-0">
				<div className="movie-header" style={{ backgroundImage: `url(${prefix + list.backdrop_image})` }}>
					<div className="movie-bigtitle">
						<div className="movie-maintitle"><h2>{list.original_title}</h2></div>
						<div className="movie-smalltitle">
							<p className="movie-lefttitle">{list.runtime} Minutes  |{new Date(list.release_date).getFullYear()}  | {Country}   | {list.original_language} </p> 
							<div className="movie-righttitle">
								<span>{genre} | {parseFloat(list.vote_average).toFixed(2)}/10</span> <span className="imdb">  IMDB  </span>
								<img style={{ width: '50px', height:'50px'  }} src={flag}></img>
							</div>
						</div>
					</div>
					
					{/* <div className="header-buttons">
					<button className="open-login"> <i className="far fa-heart" aria-hidden="true"></i></button>
					
					</div> */}
					
				</div>
			</div>

			<div className="col-md-12">
				<a ><button id="trailer-btn" onClick={() => TrailerHandler()} className="btn play-btn dark-mode "><span className="fa fa-bullhorn" aria-hidden="true"></span> Watch Trailer</button></a>
				<a target="_blank" href={`https://rarbggo.org/torrents.php/search/?search=${list.original_title + ' ' + new Date(list.release_date).getFullYear()}&category=movies`}>	<button id="trailer-btn" className="btn play-btn dark-mode "><span className="fa fa-download" aria-hidden="true"></span> Download</button></a>
			</div>
			<div className="col-md-12 padding-top-20">
				<div className="movie-section-title">Overview</div>
			</div>
			<div className="col-md-12">
				<p className="movie-setion-content">
					{list.overview}
				</p>
			</div>
		
			{	KeyWord.length ?
				<div className="col-md-12">
				<div className="d-flex align-items-center cut_btn">

					<div className="d-flex align-items-center btn_cu">
						<div><FontAwesomeIcon icon={faTag} className="text-white" /></div>
						<h6 className="fs-5 text-white mb-0">Tags :</h6>
					</div>
					{

						KeyWord.map((item) => {
							return (
								<div className="mx-1 rounded-pill text-white bg-white py-1 px-3 d-inline block">{item.name}</div>
							)
						})
					}
				</div>

			</div> : ""}

			<div class="sharethis-inline-reaction-buttons"></div>
			<div className="col-md-12 padding-top-20">
				<div className="movie-section-title">Cast</div>
			</div>

			<div className="flix-carousel-actor">
				<div className="flix-scroll-x">
					{
						cast.map((item) => {
							return (
								<>
									{
										item.profile_image &&
										<a  href={`/Actor/${item.creditID}/${item.name.replace(/\s+/g, '-')}`}onClick={()=>ActorDetaile(item.creditID , item.name)} className="actor">

											<img src={item.profile_image} />


											<span>{item.original_name}</span>
											<label>{item.character}</label>
										</a>
									}
								</>
							)
						})
					}

				</div>
			</div>

			{/* <div className="col-md-12 padding-top-20">
				<div className="movie-section-title">Similar movies</div> */}
{/* 
				<a>
				<img src="https://i.stack.imgur.com/rklJI.png" style={{ borderRadius: "5px" }} />
				</a> */}
			{/* </div> */}

			<VideoPlayer modal={model} setModal={setmodel} VideoUrl={VideoUrl} />

		</>   
		</Container>
		</ContentWrapper>
	);
}
