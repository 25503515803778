import Helmet from "react-helmet";

const HeadTags = (props) => {
  
//   const {
//     title = "Portfolio - your name",
//     metaDescription = "defaul tdescription",
//   } = props;
  return (
    <Helmet>
    <meta  property="og:title"  content={ props.title} />
    <meta property="og:description" content={props.description} />
    <meta property="og:image" content={props.image} />
  </Helmet>
  );};

  export default HeadTags;