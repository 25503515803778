import "./SingleChannel.css";
import Container from "react-bootstrap/Container";

import FatFooter from "../Footer/FatFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";
import { useEffect } from "react";
import { useQuery } from "../Search/search";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedMeta } from "../../action/MetaAction";
import DefaultImg from '../../img/site.jpg'

const Channels = () => {
	const query = useQuery();
	const FilterId = useSelector((state) => state.FilterObj.selectedFilterId );
	const dispatch = useDispatch();
	const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(title, desc, image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}

	useEffect(() => {
		window.scrollTo(0, 0)
		Metadispatch('Tv Channel' , 'Watch the world FREE' , DefaultImg)
	}, [])
	return (
		<>
			<ContentWrapper className="single-channel-page">
				<SingleChannelNav count={query.get('sort_by')} catid={query.get('id')} catname={query.get('name')} />
				<Container fluid >
					<ChannelVideos />
				</Container>
			</ContentWrapper>

			{/* <FatFooter /> */}

		</>
	);
};

export default Channels;
