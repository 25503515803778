import React, { useEffect, useRef } from "react";
const $ = window.jQuery;
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setSelectedTvChannel } from '../../action/TvChannelAction.js'



const SeriesCard = ({ Seriesname, href = '#', coverImageSrc , Id }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const GotoSeriesDetaile = (Sid, Sname) => {
        localStorage.setItem('selectedChannel', Sid);
        dispatch(
            setSelectedTvChannel(Sid)
        ).then((res) => {
            const URL = Sid + "/" + Sname.replace(/\s+/g, '-')
            history.push('/SeriesDetaile/' + URL );

        }).catch((err) => {
            console.log("redux ---", err);
        })
    }


    return (
        <>
            <a href={`/SeriesDetaile/${Id}/${Seriesname.replace(/\s+/g, '-')}`} className="poster col-4 col-md-2 col-xl-2 col-xxl-2" onClick={() => GotoSeriesDetaile(Id, Seriesname)}>
                <img className="img-fluid w-100" src={coverImageSrc} style={{ borderRadius: "5px" }} />
            </a>
        </>
    )

}
export default SeriesCard;

