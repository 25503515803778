import { SELECTED_COUNTRY ,SELECTED_COUNTRYFlag ,SELECTED_COUNTRYName } from "./config.js";

export const setSelectedCountry = ( selectedcountry , selectedcountryFlag ,selectedcountryName) => dispatch =>{
    return new Promise((resolve , rejected)=>{
        dispatch(
            {type:SELECTED_COUNTRY , selectedcountry },
            {type:SELECTED_COUNTRYFlag , selectedcountryFlag },
            {type:SELECTED_COUNTRYName , selectedcountryName }
        );
        resolve();
    })
}