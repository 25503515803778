import { SELECTED_TVCHANNEL } from "../action/config";

const initialState ={
    selectedChannelId : ''
};

export default function TvchannelReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_TVCHANNEL : 
        return{
            ...state,
            selectedChannelId : action.selectedChannel
        }
        default :
        return state
    }
}