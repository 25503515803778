import Reaact, { useEffect, useRef, useState } from "react";

import { useQuery } from "../Search/search";
import Container from 'react-bootstrap/Container';

import ReactPlayer from 'react-player';
import axios from "axios";
import Imgurl from '../../img/bg.svg'
import APIUrl from "../../common/Urlcontant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import "./Player.css"
import { useSelector } from "react-redux";

const Player = () => {
    const videoElement = useRef(null);
    const [playing, setPlaying] = useState(true)
    const [videosource, sevideosource] = useState('')
    const [iconPlay, setPlay] = useState(<FontAwesomeIcon icon={faPlay} />)
    const [iconpause, setPause] = useState(<FontAwesomeIcon icon={faPause} />)
    const ChannelId = useSelector((state) => state.ChannelObj.selectedChannelId || localStorage.getItem('selectedChannel'));
    let query = useQuery();


    const SourceUrl = async () => {
         ;
        await axios.get(APIUrl + `api/tv/channel-source/?channel_id=${ChannelId}`).then((res) => {
            sevideosource(res.data.results[0]?.source);
        })

    }


    // function playVideo() {
    //     videoElement.current.play()
    // }
    // function pauseVideo() {
    //     videoElement.current.pause();
    // }
    // function toggleControls(){
    //     videoElement.current.controls = !videoElement.current.controls;
    // }



    useEffect(() => {
        SourceUrl();
        // playVideo()

    }, [videosource, playing])

    return (

        <div id="content-wrapper">
            <Container fluid className="py-0">
                <div className="vidio_bg" >
                    <div className="video-wrapper">
                        <ReactPlayer className='vidio_cu mx-auto w-100 h-100'
                            url={videosource}
                            // url="https://spotlight-redbox.amagi.tv/playlist360p.m3u8"
                            playerRef={videoElement}
                            playIcon={false}
                            light={<img style={{ color: "white", width: "70px", border: "2px dashed white", borderRadius: "60px" }} src={Imgurl} alt='Thumbnail' />}
                            playing={playing}
                            controls

                        />






                    </div>
                </div>
            </Container>

        </div>

    )
}

export default Player