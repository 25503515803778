import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import Navigation from "./components/Navigation/Navigation";
import Sidebar from "./components/Sidebar/Sidebar";
import Homepage from "./components/Homepage/Homepage";
import Channels from "./components/Channels/Channels";
import SingleChannel from "./components/SingleChannel/SingleChannel";
import VideoPage from "./components/VideoPage/VideoPage";

import Categories from "./components/Categories/Categories";
import Upload from "./components/Upload/Upload";
import Blank from "./components/Blank/Blank";
import FourZeroFour from "./components/FourZeroFour/FourZeroFour";
import Contact from "./components/Contact/Contact";
import Subscriptions from "./components/Subscriptions/Subscriptions";
import Auth from "./components/Auth/Auth";
import Settings from "./components/Settings/Settings";
import Account from "./components/Account/Account";
import Series from "./components/Series/Series";

import { CustomScrollToTop } from "./components/Atomics/ScrollToTop/ScrollToTop";
import Search from "./components/Search/search";
import Movie from "./components/Movie/Movie";
import Actors from "./components/Actors/Actors";
import Countries from "./components/Countries/Countries";
import TvchannelDetail from "./components/TvchannelDetail/TvchannelDetail"
import Player from "./components/Player/Player"
import ActorDetail from "./components/Actors/ActorDetail";
import Birthday from "./components/Birthday/Birthday";
import Genres from "./components/Geners/geners";
import CountryDetaile from "./components/ByCountry/CountryDetaile";
import SeriesDetaile from "./components/Series/SeriesDetaile";
import EpisodeDetaile from "./components/Series/EpisodeDetaile";
import SingleChannelHero from "./components/SingleChannel/SingleChannelHero";
import { useSelector } from "react-redux";
import { HelmetProvider } from "react-helmet-async";
import MetaTag from "./common/MetaTag";
import Policy from "./components/Policy";
import Terms from "./components/Terms";
import HeadTags from "./common/Helmet";


function App() {
	const [showMenu, setShowMenu] = useState(true);


	useEffect(() => {
		if (showMenu) {
			document.body.classList.add("sidebar-toggled");
		} else {
			document.body.classList.remove("sidebar-toggled");
		}
	}, [showMenu]);



	return (
		<>
			<Router>
				<Switch>
					<Route path="/auth" component={Auth} />
					<Route path="/">
						<Content showMenu={showMenu} setShowMenu={setShowMenu} />
					</Route>
					{/* <Route path="/Movies" component={Movie} /> */}
					<Route path="*" component={FourZeroFour} />
				</Switch>
			</Router>
			{/* POST /webmaster/api.svc/pox/SubmitUrlBatch?apikey=sampleapikeyEEDECC1EA4AE341CC57365E075EBC8B6 HTTP/1.1
			Content-Type: application/xml; charset=utf-8
			Host: ssl.bing.com */}

			{/* <SubmitUrlBatch xmlns="http://schemas.datacontract.org/2004/07/Microsoft.Bing.Webmaster.Api">
				<siteUrl>http://example.com</siteUrl>
				<urlList />
				<string xmlns="http://schemas.microsoft.com/2003/10/Serialization/Arrays">http://example.com/url1</string>
				<string xmlns="http://schemas.microsoft.com/2003/10/Serialization/Arrays">http://example.com/url2</string>
				< urlList />
			</SubmitUrlBatch> */}
		</>

	);
}

function Content(props) {
	const GenerId = useSelector((state) => state.GenerObj.selectedGenerId);
	const FilterId = useSelector((state) => state.FilterObj.selectedFilterId);
	const TvFilterId = useSelector((state) => state.TvFilterObj.selectedtvFilterlId);


	return (
		<>
			<HelmetProvider>
				<MetaTag />
				<HeadTags />
				<div className="homepage-wrapper">
					<Navigation props={props} />
					<div id="wrapper" style={{ overflow: 'hidden !important' }}>
						<Sidebar props={props} />

						<Switch>
							<Route exact path="/">
								<Homepage />
							</Route>
							{!TvFilterId ?

								<Route exact path="/channels">
									<Channels />
								</Route>
								:
								<Route exact path="/channels/:id/:name">
									<Channels />
								</Route>}

							{GenerId || FilterId ?
								<Route exact path="/Movies/:id/:name">
									<Movie />
								</Route>
								: <Route exact path="/Movies">
									<Movie />
								</Route>}


							<Route exact path="/Movie/:id/:name">
								<SingleChannelHero />
							</Route>
							<Route exact path="/Birthday">
								<Birthday />
							</Route>
							<Route exact path="/Actor/:id/:name">
								<ActorDetail />
							</Route>
							<Route exact path="/Series">
								<Series />
							</Route>
							<Route exact path="/SeriesDetaile/:id/:name">
								<SeriesDetaile />
							</Route>
							<Route exact path="/episodes/:id/:name">
								<EpisodeDetaile />
							</Route>
							<Route exact path="/Country/:name">
								<CountryDetaile />
							</Route>

							<Route exact path="/Actors">
								<Actors />
							</Route>
							<Route exact path="/policy">
								<Policy />
							</Route>
							<Route exact path="/terms">
								<Terms />
							</Route>
							<Route exact path="/Player/:name">
								<Player />
							</Route>
							<Route exact path="/World">
								<Countries />
							</Route>
							<Route exact path="/single-channel">
								<SingleChannel />
							</Route>
							<Route exact path="/tv/:name">
								<TvchannelDetail />
							</Route>

							<Route exact path="/genre">
								<Genres />
							</Route>
							<Route exact path="/video-page">
								<VideoPage />
							</Route>

							<Route exact path="/categories">
								<Categories />
							</Route>

							<Route exact path="/upload">
								<Upload />
							</Route>

							<Route exact path="/search">
								<Search />
							</Route>

							<Route exact path="/blank">
								<Blank />
							</Route>
							<Route exact path="/contact">
								<Contact />
							</Route>
							<Route exact path="/subscriptions">
								<Subscriptions />
							</Route>
							<Route exact path="/settings">
								<Settings />
							</Route>
							<Route exact path="/account">
								<Account />
							</Route>

							<Route path="*" component={FourZeroFour} />
						</Switch>
					</div>
					<ScrollToTop
						smooth
						component={<CustomScrollToTop />}
						className="scroll-to-top outline-0"
						color="white"
					/>
				</div>
			</HelmetProvider>
		</>
	);
}

export default App;
