import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import CountriesCard from "./CountriesCard";
import Paginate from "../Atomics/Paginate/Paginate";
import "./SingleChannel.css";

import FatFooter from "../Footer/FatFooter";
import axios from "axios";
import APIUrl from "../../common/Urlcontant";
import { useEffect, useState } from "react";
import { cacheable } from "../../common/Caches";

const ChannelVideos = () => {

	const [Country, setcountry] = useState([])



	const country = async () => {

		axios.get(APIUrl + `api/tv/country-channel/`).then((res) => {
			setcountry(res.data.results)
		})
	}



	useEffect(() => {
		country();
		cacheable(localStorage.setItem('testList',JSON.stringify(Country)))
	}, [])

	return (
		<>
			<div className="video-block section-padding">

				{/* <Col >
						<SectionHeader   heading="Read 6753 Countries magazines for free!" />
					</Col> */}
				<div className="fleg_con">
					{
						Country?.map((item) => {
							return (
								<>

									<CountriesCard
										
										name={item.name}
										id={item.id}
										coverImageSrc={item.image}
										count={item.channel_count}
										
									/>


								</>

							)
						})
					}
				</div>



			</div>
			{/* <Paginate /> */}
			<FatFooter />
		</>
	);
};

export default ChannelVideos;
