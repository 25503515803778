import React, { useEffect, useState } from "react";
import './search.css'
import { Button, Form } from "react-bootstrap";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { useHistory } from "react-router-dom";
import axios from "axios";

// import '../../assest/js/custom'
import {
  BrowserRouter as Router,
  Link,
  useLocation
} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ImgUrl from '../../img/tv.jpg'
import FatFooter from "../Footer/FatFooter";
import APIUrl from "../../common/Urlcontant";
import { useDispatch } from "react-redux";
import { setSelectedTvChannel } from '../../action/TvChannelAction.js'

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Search = () => {
  let query = useQuery();
  const history = useHistory();
  const [list, setlist] = useState([])
  const [ActorList, setActorList] = useState([])
  const [Tvchannel, setTvchannel] = useState([])
  const [Series, setSeries] = useState([])
  const [language, setlanguage] = useState('')


  const apiKey = '8c8960e929b3daeea44acef93cd6fdf5';
  const prefix = 'https://image.tmdb.org/t/p/original/'
  const dispatch = useDispatch();


  const getLanguage = (code) => {
    const lang = new Intl.DisplayNames(['en'], { type: 'language' });
    return lang.of(code);
  }
  const SearchHandler = (e) => {

    if (query.get('query')) {
      axios.get(APIUrl + `api/tv/search/?query=${query.get('query')}`).then((res) => {
        try {
          if (res) {

            const data = res.data.movies.forEach((el) => {
              el.LanguageFullName = getLanguage(el.original_language);
            })
            const Sdata = res.data.series.forEach((el) => {
              el.LanguageFullName = getLanguage(el.original_language);
            })
            setlist(res.data.movies)
            setActorList(res.data.artist)
            setTvchannel(res.data.channels)
            setSeries(res.data.series)




          }

        } catch (error) {

        }
      })
    }

  };

  const GotoSeriesDetaile = (id ,name)=>{
    localStorage.setItem('selectedChannel', id);
    dispatch(
        setSelectedTvChannel(id)
    ).then((res) => {
        const URL = id + "/" + name.replace(/\s+/g, '-')
        history.push('/SeriesDetaile/' + URL );

    }).catch((err) => {
        console.log("redux ---", err);
    })
  }

  const GotoMovieDetaile = (Mid, Mname) => {
    localStorage.setItem('selectedChannel', Mid);
    dispatch(
        setSelectedTvChannel(Mid)
    ).then((res) => {
        const URL = Mid + "/" + Mname.replace(/\s+/g, '-')
        history.push('/Movie/' + URL );

    }).catch((err) => {
        console.log("redux ---", err);
    })
}


  
const GotoTVdeatile = (id, name) => {
  localStorage.setItem('selectedChannel', id);
  dispatch(
    setSelectedTvChannel(id)
  ).then((res) => {
    // const URL = '/tv/' + ;
    history.push('/tv/' + name.replace(/\s+/g, '-'));

  }).catch((err) => {
    console.log("redux ---", err);
  })
}

  const ActorSearchResult = (Aid , Aname) => {
      // history.push(`/Actor?id=${id}`)
      
        localStorage.setItem('selectedChannel', Aid);
        dispatch(
          setSelectedTvChannel(Aid)
        ).then((res) => {
          const URL = Aid + "/" + Aname.replace(/\s+/g, '-')
          history.push('/Actor/' + URL );
    
        }).catch((err) => {
          console.log("redux ---", err);
        })
      
       
  }

  useEffect(() => {
    SearchHandler();
    // ActorSearchResult();
  }, [query.get('query')])


  return (
    <>
      {
        // Tvchannel.length && list.length && Series.length && ActorList.length ?
          <ContentWrapper className="single-channel-page">

            {Tvchannel.length ? <div class="video-block section-padding">
              <div class="row">
                <div class="col-md-12">
                  <div class="main-title">
                    <br></br>
                    <br></br>
                    <h6>Channel search results</h6>
                  </div>
                </div>

                <div class="grid">
                  {
                    Tvchannel.map((item, index) => {
                      return (

                        <div key={index}>
                          <div class="video-card" onClick={()=>GotoTVdeatile(item.id, item.name)} >
                            <div class="video-card-image">
                              <a ><img class="grid_item" style={{ Height: "300px" }} src={item.image?.url ? item.image?.url : ImgUrl} alt="" /></a>
                              <div class="video-card-body">
                                <div class="video-title">
                                  <a >{item.name}</a>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
              : <h3 style={{ textAlign: "center", marginTop: "65px", fontFamily: "fantasy" }}>No Tvchannel found</h3>
            }

            {list.length ? <div class="video-block section-padding">
              <div class="row">
                <div class="col-md-12">
                  <div class="main-title">
                    <br></br>
                    <br></br>
                    <h6>Movie search results</h6>
                  </div>
                </div>

                <div class="grid">
                  {
                    list.map((item, index) => {
                      return (
                        item.poster_path &&
                        // class="col-lg-2 col-md-3 col-sm-6 mb-3 px-3"
                        <div key={index}>
                          <a href={'/Movie/' + item.id + "/" + item.title.replace(/\s+/g, '-')}  class="video-card" onClick={() => GotoMovieDetaile(item.id, item.title)}>
                            <div class="video-card-image">
                              <div className="position-relative">
                                <a class="play-icon" ><i class="fas fa-play-circle"></i></a>

                                <img class="grid_item" style={{ Height: "300px" }} src={prefix + item.poster_path} alt="" />
                                <div class="laguage">{item.LanguageFullName}</div>

                                <div class="adult">{item.adult == true ? '18+' : ''}</div>
                                <div class="time">{item.vote_average > 0 ? parseFloat(item.vote_average).toFixed(2) : ''}</div>
                                <div class="releace">{new Date(item.release_date).getFullYear()}</div>
                              </div>

                              <div class="video-card-body">
                                {/* <div class="video-title"> */}
                                <a >{item.title}</a>
                                {/* </div> */}
                              </div>
                            </div>

                          </a>
                        </div>

                      )
                    })
                  }
                </div>
              </div>
            </div>
              : <h3 style={{ textAlign: "center", marginTop: "65px", fontFamily: "fantasy" }}>No Movie found</h3>}
            {
              Series.length ?
                <div class="video-block section-padding">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="main-title">
                        <br></br>
                        <br></br>
                        <h6>Series search results</h6>
                      </div>
                    </div>

                    <div class="grid">
                      {
                        Series.map((item, index) => {
                          return (
                            item.poster_path &&
                            // class="col-lg-2 col-md-3 col-sm-6 mb-3 px-3"
                            <div key={index}>
                              <a href={'/series/'+item.id + "/" + item.original_name.replace(/\s+/g, '-')} onClick={()=>GotoSeriesDetaile(item.id , item.original_name)} class="video-card">
                                <div class="video-card-image">
                                  <div className="position-relative">
                                    <a class="play-icon" ><i class="fas fa-play-circle"></i></a>

                                    <img class="grid_item" style={{ Height: "300px" }} src={prefix + item.poster_path} alt="" />
                                    <div class="laguage">{item.LanguageFullName}</div>

                                    <div class="adult">{item.adult == true ? '18+' : ''}</div>
                                    <div class="time">{item.vote_average > 0 ? parseFloat(item.vote_average).toFixed(2) : ''}</div>
                                    {/* <div class="releace">{new Date(item.release_date).getFullYear()}</div> */}
                                  </div>

                                  <div class="video-card-body">
                                    {/* <div class="video-title"> */}
                                    <a >{item.title}</a>
                                    {/* </div> */}
                                  </div>
                                </div>

                              </a>
                            </div>

                          )
                        })
                      }
                    </div>
                  </div>
                </div> : <h3 style={{ textAlign: "center", marginTop: "65px", fontFamily: "fantasy" }}>No Series found</h3>}

            {
              ActorList.length ?
                <div class="video-block section-padding">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="main-title">
                        <br></br>
                        <br></br>
                        <h6>Actor search results</h6>
                      </div>
                    </div>

                    <div class="grid">
                      {
                        ActorList.map((item, index) => {
                          return (
                            item.profile_path &&

                            <div key={index}>
                              <div class="video-card" onClick={() => { ActorSearchResult(item.id , item.name) }}>
                                <div class="video-card-image">
                                  <a href={`/Actor/${item.id}/${item.name.replace(/\s+/g, '-')}`} ><img class="grid_item" style={{ Height: "300px" }} src={prefix + item.profile_path} alt="" /></a>
                                  <div class="video-card-body">
                                    <div class="video-title">
                                      <a >{item.name}</a>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  </div>
                </div> : <h3 style={{ textAlign: "center", marginTop: "65px", fontFamily: "fantasy" }}>No Actor found</h3>
            }



          </ContentWrapper>

          // : <h1 style={{ textAlign: "center", marginTop: "65px", fontFamily: "fantasy" }} > No Search result found </h1>
      }
      <FatFooter />
    </>
  )
}

export default Search;