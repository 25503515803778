import "./SingleChannel.css";
import Container from "react-bootstrap/Container";

import FatFooter from "../Footer/FatFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";
import SingleChannelNav from "./SingleChannelNav";
import ChannelVideos from "./ChannelVideos";

const SingleChannel = () => {
	 ;

	return (
		<>
			<ContentWrapper className="single-channel-page">

				<SingleChannelHero   />
				<ChannelVideos />
				
			</ContentWrapper>

			{/* <FatFooter /> */}
		</>
	);
};

export default SingleChannel;
