import React from 'react'
import { Container } from 'react-bootstrap'
import ContentWrapper from './Atomics/ContentWrapper/ContentWrapper'
import "./Policy.css"

export default function Policy() {
    return (
        <>

            <ContentWrapper className="single-channel-page">
                <div className='mt-5' style={{ textAlign: "justify", color: "#fff" }}>
                    <h3 className='text-center'>Privacy Policy</h3>
                    <h6 className='text-center custom_space' >TV ZINOS / Rosedog Ltd considers the security of your own data a best need. Our arrangements in regards to the gathering, utilize and revelation of data gathered from you is liable to the laws of The United States of America.</h6>
                    <div className='px-4 pt-4'>
                        <div>1. We are capable just for the individual data under our control. In any case, if the substance gave by our administration has substance or web connects that prompt unlawful or grown-up arranged data, we are not by any suit in charge of it as we don't control or screen the substance or the security practices of magazines and different sites.</div>

                        <div className='pt-3'>  2. While asking for or distributing content through TV ZINOS / Rosedog Ltd, you will be asked for to validate insights about your name, email address and so forth. Obviously, you have the flexibility to choose your client name and uncover or not reveal discretionary data about you.</div>

                        <div className='pt-3'> 3. While making a buy, TV ZINO / Rosedog Ltd S will approach you for more data to finish the procedure and this data will be put away in your record until the point that you look for our assistance to expel them. Other data may incorporate email addresses, passwords, charging addresses, membership points of interest, individual data and so on.</div>

                        <div className='pt-3'>  4. TV ZINOS / Rosedog Ltd holds the privilege to store data in regards to your utilization of the administration and may share these with outsiders including distributers.</div>

                        <div className='pt-3'>  5. You should be no less than 13 years of age to have our consent to utilize this Site. We don't intentionally gather, utilize or unveil individual data about guests under 13 years old. On the off chance that you are younger than 13, you can utilize this administration just in conjunction with your parent's or watchman's authorization.</div>

                        <div className='pt-3'>  6. We utilize information accumulation gadgets, for example, "treats" on specific pages of the Website to help investigate our site page stream, measure limited time viability, and advance trust and wellbeing. "Treats" are little records set on your hard drive that help us in giving our administrations. We offer certain highlights that are just accessible using a "treat". We likewise utilize treats to enable you to enter your secret word less as often as possible amid a session. Treats can likewise enable us to give data that is focused to your interests. Most treats are "session treats," implying that they are naturally erased from your hard drive toward the finish of a session. You are constantly allowed to decay our treats if your program grants, in spite of the fact that all things considered you will be unable to utilize certain highlights on the Website and you might be required to re-enter your secret word all the more often amid a session. Moreover, you may experience "treats" or other comparable gadgets on specific pages of the Website that are set by outsiders. We don't control the utilization of treats by outsiders.</div>

                        <div className='pt-3'>  7. Data we accumulate on our site is put away inside TV ZINOS / Rosedog Ltd -controlled databases (or databases facilitated for our benefit) on servers kept up in ensured conditions. Any charge card data you give us is secured to transmission over the Internet utilizing industry-standard secure attachments layer (SSL) encryption. Nonetheless, no physical or electronic security framework is invulnerable. We can't ensure the security of our servers or databases, nor would we be able to ensure that data you supply won't be caught while being transmitted to us over the Internet. In the far-fetched occasion that we trust the security of your own data in our ownership or control may have been traded off, we may try to inform you of that advancement. In the event that warning is suitable, we would try to do as such as immediately as conceivable considering the present situation, and, to the degree we have your email address, we may tell you by email.</div>

                        <div className='pt-3'> 8. Your client account is ensured by a secret key chose by you.</div>

                        <div className='pt-3'>   9. We will reveal your own data, just with your assent and not something else, unless requested by law.</div>
                        <div className='pt-3'>   10. We gather individual data by reasonable and legal means. Also, such gathered data fills just constrained needs, such as taking care of the requests of TV ZINOS' / Rosedog Ltd client profile as set out in our organization strategy.</div>


                        <div className='pt-3'>    11. We ensure your own data by security shields relying upon the affectability of the data gathered. Money related information or charging data is treated with extreme concern and security.
                            Privacy Policy is of April first 2021.
                        </div>
                    </div>
                </div>
            </ContentWrapper>


        </>
    )
}
