import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroadcastTower, faCalendar, faDesktop, faFilm, faFilter, faGlobe, faGripLines, faHome, faMagic, faMusic, faVideo } from "@fortawesome/free-solid-svg-icons";

const NavItem = ({ href, label, active, children }) => {
	return (
		<>
			<li className={active ? "nav-item active" : "nav-item"}>
				<a href={href} className="nav-link">
					{/* <FontAwesomeIcon icon={faIcon} fixedWidth />{" "} */}
					{label == 'Home' &&
						// <i class="fas fa-home" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faHome} />
					}
					{label == 'Tv Channels' &&
						// <i  class="fas fa-desktop" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faDesktop} />

					}
					{label == 'Movies' &&
						// <i class="fas fa-film" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faFilm} />

					}
					{label == 'TV Series' &&
						// <i class="fas fa-film" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faGripLines} />

					}
					{label == 'Genres' &&
						// <i class="fas fa-filter" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faFilter} />

					}
					{label == 'Actors' &&
						// <i class="fas fa-video" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faVideo} />

					}
					{label == 'Country' &&
						// <i class="fa fa-globe" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faGlobe} />

					}
					{label == 'Birthday' &&
						// <i class="fa fa-calendar" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faCalendar} />

					}
					{label == 'Music' &&
						// <i class="fa fa-calendar" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faMusic} />

					}
					{label == 'Magazine' &&
						// <i class="fa fa-calendar" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faMagic} />

					}
					{label == 'Redio' &&
						// <i class="fa fa-calendar" aria-hidden="true"></i>
						<FontAwesomeIcon icon={faBroadcastTower} />
					}



					<span>{label}</span>
				</a>
			</li>
		</>
	);
};

export default NavItem;
