import axios from "axios";
import React, { useEffect, useRef } from "react";
import APIUrl from "../../common/Urlcontant";
// import "./SingleChannel.css"
const $ = window.jQuery;

import imgUrl from "../../img/tv.jpg";
import SingleChannelNav from "./SingleChannelNav";
import { useDispatch } from "react-redux";
import {setSelectedTvChannel} from '../../action/TvChannelAction'
import { useHistory } from "react-router-dom";



const ChannelsCard = ({ name, href = '#', coverImageSrc,id, count }) => {

    const dispatch = useDispatch();
    const history = useHistory()

    
	const GotoTVdeatile = (id, name) => {
		localStorage.setItem('selectedChannel', id);
		dispatch(
			setSelectedTvChannel(id)
		).then((res) => {
			// const URL = '/tv/' + ;
			history.push('/tv/' + name.replace(/\s+/g, '-'));

		}).catch((err) => {
			console.log("redux ---", err);
		})
	}
   

    return (
        <>
            <a href={`/tv/${name.replace(/\s+/g, '-')}`}  className="poster col-4 col-xsm-4 col-sm-4 col-md-2 col-xl-2 col-xxl-2" onClick={()=>GotoTVdeatile(id, name)}  >
                <img className="img-fluid w-100 " src={coverImageSrc ? coverImageSrc : imgUrl} style={{ borderRadius: "5px" }} />
                <p className="cu_text">{name}</p>
            </a>
        </>
    )

}
export default ChannelsCard;

