import React from 'react'
import ContentWrapper from './Atomics/ContentWrapper/ContentWrapper'

export default function Terms() {
    return (
        <ContentWrapper className="single-channel-page"  >
            <div className='mt-5' style={{ textAlign: "justify", color: "#fff" }}>
                <h3 className='text-center'>Terms & Conditions</h3>
                <h6 className='text-center custom_space' >Last updated April 1, 2021 Welcome to TV ZINOS, that are going for to be the world's largest digital TV channels service! Access to, browsing and use of the TV ZINOS website, Internet services and mobile device apps ("App") are subject to the following Terms and Conditions ("Terms"). Please read these Terms carefully. Creating an account with TV ZINOS ("we", "us") or otherwise using any TV ZINOS or TV ZINOS related service or App will signify that you have read, understood, and agree to be bound by these Terms and to comply with all applicable laws and regulations. These Terms constitute a binding agreement between you and TV ZINOS.com / Rosedog Ltd. and affiliates. If you do not agree to these Terms, you may not use the Service. THESE TERMS CONTAIN WARRANTY DISCLAIMERS AND OTHER PROVISIONS THAT LIMIT THE LIABILITY OF TV ZINOS, SO PLEASE READ THESE TERMS IN THEIR ENTIRETY. TV ZINOS reserves the right to immediately terminate your access to the website, service or TV ZINOS related app, if you do not comply with this agreement. The TV ZINOS website, Internet services and mobile device apps collectively constitute the "Service". This Service is owned and operated by TV ZINOS.com / Rosedog Ltd. The Service includes the website: www.TV ZINOS.com and the App that provides you the capability to order, subscribe to and receive the delivery of various TV channels, magazines, E- books, Audiobooks, news and magazine and publication related content (collectively known as "Publications") and to have these digital versions of the Publications delivered to you.</h6>
                <div className='px-4 pt-4'>
                    <div>1. Certain portions of the Service are available only to individuals who have registered with and obtained a password from TV ZINOS. By registering with TV ZINOS, you certify that you are at least 18 years of age. While registering with TV ZINOS, you should provide true and accurate data which includes that of your geographical location, personal details, billing information, etc. The accountability for maintaining and updating this data lies solely with you. TV ZINOS reserves the right for terminating the account for untrue or inaccurate data at any time if found or proved. The responsibility for maintaining the confidentiality of the password and the account information rests entirely with you. You agree to immediately notify TV ZINOS of any unauthorized use of your password or account or any other breach of security.</div>

                    <div className='pt-3'>  2. You will maintain and promptly update that information to keep it true, accurate, current and complete. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account, including the selection and use of all content and services.</div>

                    <div className='pt-3'> 3. Without prior written or authorised permission from TV ZINOS, you should not modify, copy, distribute or sell any content obtained from TV ZINOS as everything published here is the proprietary property of TV ZINOS or its licensors. As such unauthorised use may also violate applicable laws including copyright and trademark laws, TV ZINOS strictly prohibits these and will terminate your access and account if any such illegal activity is proved or detected. You should also stand liable for the damages caused by these unauthorised activities. Except as permitted by the Publication's lawful owner, you acknowledge that you do not acquire any ownership rights by downloading Publications accessed through the Service. Except as explicitly required under copyright law or permitted by the features of the Service you may not modify, reverse engineer, publish, transmit, display, participate in the transfer or sale, create derivative works of, or in any way commercially exploit or provide to a third party the content of the App, the Publications, or any portion of them without the express permission of TV ZINOS and the owner of such content. We do not grant you any licenses, express or implied, to the intellectual property of TV ZINOS or TV ZINOS's licensors</div>

                    <div className='pt-3'>  4. TV ZINOS reserves the right to refuse service, terminate accounts, or cancel orders at any time in its sole discretion, including, without limitation, based upon any activity by a subscriber in violation of these Terms or applicable law.</div>

                    <div className='pt-3'>  5. You agree not to misrepresent geographical locations, use proxies, use IP spoofing or by any other means to hide the origin of any message you send or purchase you make through the service. You agree not to pretend as any other individual or identity.</div>

                    <div className='pt-3'>  6. The Service is controlled, operated and administered by TV ZINOS from its offices within the UK and Stockholm , Sweden. If you access the Service from a location outside the United States, you are responsible for compliance with all local laws that apply to you. You agree that you will not use the service, or any Publications accessed through the Service in any country or in any manner prohibited by the UK government or any other applicable laws, restrictions or regulations.</div>

                    <div className='pt-3'>  7. TV ZINOS reserves all rights to monitor the content published and inputted by you for the purpose of determining whether you are compliant with these terms and conditions and other operating rules determined by TV ZINOS. TV ZINOS has the right to take decisions about the content and delete them at its own will.</div>

                    <div className='pt-3'> 8. TV ZINOS is a distributor and NOT a publisher of the Publications and so TV ZINOS has no editorial control over the content, including opinions, advice, statements, services, offers etc., that is represented in the content. So TV ZINOS will not take responsibility for the accuracy or the reliability for any information or facts provided therein or that is made available through the service, by anyone other than authorised TV ZINOS employees who are acting on their official capacities. Accordingly, we have no editorial control over the Publications / content. Any opinions, advice, statements, services, offers or other information or content expressed or made available by third parties, including those made in Publications offered by the Service, are those of the respective author(s) or publisher(s) and not of TV ZINOS. TV ZINOS will not be liable for any loss or damage caused by your reliance on information obtained through the Service or from the Publications. The Service may contain links to other Internet sites and third-party resources and TV ZINOS does not assume any responsibility or liability for communications or materials available at such linked sites. These links are provided for your convenience only. You are solely responsible for understanding any terms and conditions that may apply when you visit or place an order through a third-party site.</div>

                    <div className='pt-3'>   9. TV ZINOS reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice at any time. You agree that TV ZINOS shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</div>

                    <div className='pt-3'>   10. THE SERVICE AND ALL PUBLICATIONS / CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TV ZINOS, ITS AFFILIATES, SUPPLIERS, LICENSORS AND AGENTS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, NEITHER TV ZINOS NOR ITS AFFILIATES, SUPPLIERS, LICENSORS OR AGENTS, MAKES ANY WARRANTY THAT (I) THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (III) THE INFORMATION THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE OR THAT MAY BE CONTAINED IN THE PUBLICATIONS / CONTENT  WILL BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PUBLICATIONS / CONTENT  OR THE SERVICE WILL MEET YOUR EXPECTATIONS. YOU ARE SOLELY RESPONSIBLE FOR OBTAINING AND MAINTAINING RELIABLE INTERNET CONNECTIVITY AND YOUR DEVICE. TV ZINOS IS NOT RESPONSIBLE FOR YOUR INABILITY TO DOWNLOAD CONTENT. ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SERVICE IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM TV ZINOS OR THROUGH OR FROM THE SERVICE SHALL CREATE ANY WARRANTY.</div>


                    <div className='pt-3'>    11. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER TV ZINOS NOR ITS AFFILIATES, SUPPLIERS LICENSORS, OR AGENTS SHALL BE LIABLE UNDER ANY THEORY OF LAW, FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE AGGREGATE LIABILITY OF TV ZINOS FOR CLAIMS ARISING FROM YOUR USE OF THE SERVICE OR PUBLICATIONS / CONTENT  OR OTHERWISE SHALL BE LIMITED TO THE FEES PAID BY YOU TO TV ZINOS FOR THE PORTION OF THE SERVICE OR PUBLICATION / CONTENT  GIVING RISE TO THE CLAIM. You agree to defend TV ZINOS, its employees and its affiliates from all claims, demands, actions, damages, costs and liabilities arising out of your use or misuse of the Service.
                    </div>

                    <div className='pt-3'>    12. All sales related taxes will be paid by the user as per the taxation norms that may change from time to time based on location of the user. All pricing and tax on the Apple iTunes App will be governed by Apple's iTunes Terms and Conditions.
                    </div>

                    <div className='pt-3'>    13. Your satisfaction is very important to us. However, all sales are final and no refunds in full or in part will be issued to you.
                    </div>

                    <div className='pt-3'>    14. All subscriptions bought through TV ZINOS are auto renewable. The subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period.
                    </div>

                    <div className='pt-3'>    15. A TV ZINOS Premium subscription is for your personal, non-commercial use only. You may not use it for your business, institution or any other commercial service/purpose. If you'd like to obtain a commercial license of TV ZINOS Premium.
                    </div>

                    <div className='pt-3'>    16. "TV ZINOS", “TV ZINOS Freemium”, “TV ZINOS Premium”, and all other related words and domain names are the trademarks and the property of TV ZINOS.com / Rosedog Ltd. All other trademarks, logos, company names etc., are the property of their respective owners.
                    </div>

                    <div className='pt-3 mb-5'>    17. TV ZINOS.com do have content that is restricted for users that must be at least 18 years of age to use that content and by using the TV ZINOS website the user agreeing to these terms and conditions.
                    </div>

                    <h6 className='text-center custom_space mb-4' >These Terms and Conditions and the other rules, guidelines, licenses and disclaimers posted on the Service (on the TV ZINOS website or TV ZINOS Apps constitute the entire agreement between TV ZINOS and you with respect to your use of the Service and supersede all previous written or oral agreements between us with respect to the subject matter hereof. These Terms shall be governed by the statutes and laws of UK. From time to time, TV ZINOS may revise these Terms and Conditions to keep them up to date with TV ZINOS products and services. Please refer to the TV ZINOS website and TV ZINOS Apps periodically for any changes. The update date first written above is used to alert you to recent modifications. Your access or use of the Service subsequent to such an update will signify your assent to be bound by such changes. If you have any questions regarding our terms and conditions, please email us at: info@tvzinos.com</h6>
                </div>
            </div>
        </ContentWrapper>

    )
}
