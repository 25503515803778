import "./SingleChannel.css";
import Container from "react-bootstrap/Container";

import FatFooter from "../Footer/FatFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleChannelHero from "./SingleChannelHero";

import ChannelVideos from "./ChannelVideos";
import { useQuery } from "../Search/search";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const SingleChannel = () => {
	const query = useQuery();
	const ChannelId = useSelector((state) => state.ChannelObj.selectedChannelId || localStorage.getItem('selectedChannel'));
	const [channel, setChannel] = useState("")

	useEffect(() => {
		if (!ChannelId)
			setChannel(localStorage.getItem('selectedChannel'));
		else
			setChannel(ChannelId)
	},[ChannelId])
	return (
		<>
			<ContentWrapper className="single-channel-page">
				<div style={{ minHeight: '95vh' }}>
					{channel !== '' &&
						<SingleChannelHero id={channel} />
					}
					{/* <ChannelVideos /> */}
				</div>
			</ContentWrapper>

		</>
	);
};

export default SingleChannel;
