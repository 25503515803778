import { SELECTED_COUNTRY , SELECTED_COUNTRYFlag ,SELECTED_COUNTRYName } from "../action/config";

const initialState ={
    selectedCountryId : '',
    selectedCountryFlag : '',
    selectedCountryName : ''
};

export default function CountryReducer(state = initialState , action){
    switch(action.type){
        case SELECTED_COUNTRY : 
        return{
            ...state,
            selectedCountryId : action.selectedcountry
        }
        case SELECTED_COUNTRYFlag : 
        return{
            ...state,
            selectedCountryFlag : action.selectedcountryFlag
        }
        case SELECTED_COUNTRYName : 
        return{
            ...state,
            selectedCountryName : action.selectedcountryName
        }
        default :
        return state
    }
}