import axios from "axios";
import './CountryDetaile.css';
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import APIUrl from "../../common/Urlcontant";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";
import { useQuery } from "../Search/search";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTvChannel } from "../../action/TvChannelAction";
import { setSelectedMeta } from "../../action/MetaAction";
import { cacheable } from "../../common/Caches";

const CountryDeatile = () => {

    const query = useQuery()
    
    const location = useLocation()
    const history = useHistory();
    const dispatch = useDispatch();
    const [Detailes, setDetailes] = useState([])
    const [countryname , setcountryname] = useState('')
    const CountryId = useSelector((state) => state.CountryObj.selectedCountryId || localStorage.getItem('selectedcountry') );
    const CountryFlag = useSelector((state) => state.CountryObj.selectedCountryFlag || localStorage.getItem('selectedcountryFlag') );
    const CountryName = useSelector((state) => state.CountryObj.selectedCountryName || localStorage.getItem('selectedcountryName') );
   
     const  CName = location.pathname.replace('/Country/','')

    const deatile = () => {
        axios.get(APIUrl + `api/tv/channel/?country=${CountryId}`).then((res) => {

            setDetailes(res.data.results)
            Metadispatch(CountryName, 'Watch the world FREE', CountryFlag)


        })
    }

    const Metadispatch = (title, desc, image) => {
		dispatch(
			setSelectedMeta(`${title} | Watch`+ ' ' + `${title} Free Online - TVzinos`, desc , image)
		).then((res) => {
			// setSelectedMeta(res.data.news.title, res.data.news.details, res.data.news.image)
			console.log(res);
		})
	}

    
    
	const GototTvDetaile = (id, name) => {
		// const decode = name.replaceAll(/\s+/g, '-');
		localStorage.setItem('selectedChannel', id);
		dispatch(
			setSelectedTvChannel(id)
		).then((res) => {
			// const URL = '/tv/' + ;
			history.push('/tv/'+ name.replace(/\s+/g, '-'));

		}).catch((err) => {
			console.log("redux ---", err);
		})
	}

    useEffect(() => {
        deatile()
        cacheable(localStorage.setItem('testList',JSON.stringify(Detailes)))
    }, [])

    return (
        <ContentWrapper>
            <Container fluid>
                    <h4 className="mt-3 "  >{CName} Channels</h4>
                <div className="row" style={{ marginTop: "20px" }}>
                    {Detailes.map((item) => {
                        return (
                            <>
                                <div className="main_item col-xl-1 col-md-2 col-4 px-1">
                                    <span onClick={() => GototTvDetaile(item.id, item.name)} >
                                       <a href={'/tv/'+ item.name.replace(/\s+/g, '-')}> <img src={item.image?.url} /> </a>
                                    </span>
                                    <div className="scroll-left">
                                        <h6 className="cut-text mb-0">{item.name}</h6>
                                    </div>
                                </div>
                            </>

                        )
                    })}

                </div>

            </Container>

        </ContentWrapper>
    )

}

export default CountryDeatile;